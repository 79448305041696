/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import NavigationBar from './NavigationBar';
import FooterBar from './FooterBar';
import './App.css';

function Lxfs() {

    React.useEffect(() => {

    }, []);

    return (
        <div>

            <NavigationBar current={'我们'} />

            <div class="my_banner banner5">
                <div class="inf">
                    <h3>
                    我们
		            </h3>
                    <h4>
                    US
		            </h4>
                </div>
            </div>
            <div class="mapbox">
                <div class="maptips">
                </div>
            </div>

            <FooterBar />

        </div>
    );
}

export default Lxfs;
