/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import NavigationBar from './NavigationBar';
import FooterBar from './FooterBar';
import './App.css';

function About() {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    return (
        <div style={{height: "100vh"}}>

            <NavigationBar current={'关于'} />

            <div className="my_banner banner4">
                <div className="inf">
                    <h3>
                    关于
		            </h3>
                    <h4>
                    ABOUT
		            </h4>
                </div>
            </div>
            <div className="cp_t">
                <div className="cp_t_a">
                    <a style={{ color: currentIndex === 0 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(0)}>公司简介</a>
                    <a style={{ color: currentIndex === 1 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(1)}>客户&合作伙伴</a>
                </div>
            </div>
            
            {
                currentIndex === 0 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <div className="wrapperw3">
                        <a className="anchor" id="qyjj" name="qyjj" href="#"></a>
                        <div className="lstzz">
                            <div className="lstzz_a">
                                <p className="lstzz_en">
                                    company profile
                            </p>
                                <p className="lstzz_ch">
                                    公司简介
                            </p>
                            </div>
                        </div>
                        <div className="wh_box">
                            <div className="ab_l" style={{marginRight: 20}}>
                                <img src="/img/elt_logo.png" width="356" height="356" />
                            </div>
                            <div className="ab_r" style={{ width: 702 }}>
                                <p>上海良因信息技术有限公司一家具有丰富IT行业经验和服务理念的信息技术型公司，与这个日新月异，不断创新的IT行业共同成长。</p>
                                <p>成立以来，良因公司以客户需求为主导，逐步构建了公司经营的产品导向，现签约多家世界一流IT品牌Lenovo、HP、DELL EMC、Cisco、Microsoft等，并成为其经销商或合作伙伴。</p>
                                <p>在产品销售取得良好成绩的同时，良因公司仍不断增加投入，提升客户的服务体验，不断加强公司的技术实力，优化内部流程管理，安排专业技能培训，同时上线了定制化的ERP系统,以最大可能满足市场和客户的变化与需求。</p>
                                <p>上海良因信息技术有限公司核心团队具有丰富的IT从业经验和敏锐的市场洞察力，使公司在业务、技术、服务、管理等方面不断攀升，同时不忘营造良好的企业文化，提升企业凝聚力。</p>
                                <p>现今，随着科技创新的速度越来越快，IT产品和解决方案成为各行各业的生产力和核心竞争力，良因公司更要紧跟市场变化，深挖细节，为客户提供更优质的产品，更完善的解决方案，更细致的服务体验。</p>
                                <p>共同创造美好的明天！</p>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 1 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <a className="anchor" id="fzlc" name="fzlc" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                Customers & Partners
                        </p>
                            <p className="lstzz_ch">
                                客户&合作伙伴
                            </p>
                        </div>
                    </div>
                    <div className="wh_box" style={{ marginBottom: 50 }}>
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>我们的客户</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/partner.png' />
                            </p>

                            <p>我们的合作伙伴</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/client.png' />
                            </p>
                        </div>
                    </div>
                </div> : null
            }

            <FooterBar />

        </div>
    );
}

export default About;
