import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import About from './About';
import Products from './Products';
import Anli from './Anli';
import Lxfs from './Lxfs';
import Service from './Service';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route} from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
      <div>

          <Route exact path="/" component={App} />
          <Route exact path="/about" component={About} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/anli" component={Anli} />
          <Route exact path="/lxfs" component={Lxfs} />
          <Route exact path="/service" component={Service} />
      </div>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
