/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import NavigationBar from './NavigationBar';
import FooterBar from './FooterBar';
import './App.css';

function Products() {
    const [currentIndex, setCurrentIndex] = React.useState(1);

    React.useEffect(() => {
    }, []);

    return (
        <div style={{ height: "100vh" }}>

            <NavigationBar current={'产品'} />

            <div className="my_banner banner3">
                <div className="inf">
                    <h3>
                        产品
                    </h3>
                    <h4>
                        PRODUCTS
                    </h4>
                </div>
            </div>
            <div className="cp_t">
                <div className="cp_t_a">
                    <a style={{ color: currentIndex === 1 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(1)}>LENOVO</a>
                    <a style={{ color: currentIndex === 2 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(2)}>IBM</a>
                    <a style={{ color: currentIndex === 3 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(3)}>DELL EMC</a>
                    <a style={{ color: currentIndex === 4 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(4)}>H3C</a>
                    <a style={{ color: currentIndex === 5 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(5)}>信锐</a>
                    <a style={{ color: currentIndex === 6 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(6)}>APPLE</a>
                    <a style={{ color: currentIndex === 7 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(7)}>HUAWEI</a>
                    <a style={{ color: currentIndex === 8 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(8)}>HP</a>
                    <a style={{ color: currentIndex === 9 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(9)}>MAXHUB</a>
                    <a style={{ color: currentIndex === 0 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(0)}>CISCO</a>
                </div>
            </div>

            {
                currentIndex === 0 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="wlyx" name="wlyx" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                CISCO
                            </p>
                            <p className="lstzz_ch">
                                CISCO系列
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all">
                            <p><a href='https://www.cisco.com/c/zh_cn/products/switches/catalyst-9200-series-switches/index.html'>思科Catalyst 9200系列交换机</a></p>
                            <p>思科 Catalyst 9200 系列交换机将基于意图的网络以及 Catalyst 9000 硬件和软件创新的强大优势扩展到更广泛的部署环境。Catalyst 9200 系列秉承 Catalyst 产品的一贯传统，提供比同类产品更出色的安全性、恢复能力和可编程性。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt56291.png' />
                            </p>
                            <p>将基于意图的网络扩展到各种环境</p>
                            <p></p>
                            <p>思科® Catalyst® 9200 系列交换机将基于意图的网络以及 Catalyst 9000 硬件和软件创新的强大优势扩展到更广泛的部署环境。Catalyst 9200 系列交换机秉承 Catalyst 产品的一贯传统，不仅安全可靠，而且能够无间断工作并简化 IT，可提供不打折扣的易用性体验。</p>
                            <p></p>
                            <p>无论您在基于意图的网络转型过程中处于哪个阶段，Catalyst 9200 系列交换机作为思科全数字化网络架构 (DNA) 的基本组成部分，都可以利用其他供应商无法提供的情报、自动化和专业知识，帮助您简化复杂性、优化 IT 并降低运营成本。</p>
                            <p></p>
                            <p>Catalyst 9200 系列交换机具备安全功能，可为硬件、软件和流经交换机的所有数据提供完整性保障。该系列交换机的恢复能力也同样出色，可确保您的企业正常运行。这些功能与思科 IOS XE 的开放式 API 以及 UADP ASIC 技术的可编程性相结合，确保 Catalyst 9200 系列交换机不仅能满足您当前所需，而且能面向未来的科技创新保护您的投资。</p>
                            <p></p>
                            <p>Catalyst 9200 系列交换机具有满功率 PoE+ 功能、冗余电源和风扇、最高 160 Gbps 堆叠带宽、模块化上行链路、第 3 层功能支持和冷修补能力，其出众的恢复能力和先进的架构在业界无与伦比，是具有成本效益的分支机构接入解决方案。</p>
                            <p></p>
                            <p>产品概述</p>
                            <p></p>
                            <p>主要产品特性</p>
                            <p></p>
                            <p>●最多 48 个具有满功率增强型以太网供电 (PoE+) 功能的端口</p>
                            <p></p>
                            <p>●通过现场可更换部件 (FRU) 及冗余电源、风扇和模块化上行链路实现强大的恢复能力</p>
                            <p></p>
                            <p>●提供灵活的下行链路选项，包括数据、PoE +或 mGig</p>
                            <p></p>
                            <p>●通过可选背板堆叠实现高效运行，支持最高 160 Gbps 的堆叠带宽</p>
                            <p></p>
                            <p>●采用 UADP 2.0 Mini 和集成 CPU，可通过更合理的成本结构为客户提供更强大的扩展能力</p>
                            <p></p>
                            <p>●通过 AES-128 MACsec 加密、基于策略的分段和可信赖系统技术提供更出色的安全性</p>
                            <p></p>
                            <p>●支持第 3 层功能，包括 OSPF、EIGRP、ISIS、RIP 和路由接入</p>
                            <p></p>
                            <p>●利用 Full Flexible NetFlow 实现高级网络监控</p>
                            <p></p>
                            <p>●思科软件定义接入 (SD-Access)：</p>
                            <p></p>
                            <p>通过从边缘到云端基于策略的自动化来简化运营和部署，并使用思科身份服务引擎 (ISE) 进行管理</p>
                            <p></p>
                            <p>通过 Cisco DNA Center 提供网络状态感知并缩短解决问题的时间</p>
                            <p></p>
                            <p>●支持即插即用 (PnP)：一种简单、安全、统一的集成功能，有助于轻松部署新的分支机构或园区设备，或对现有网络进行更新</p>
                            <p></p>
                            <p>●思科 IOS XE：基于通用许可的操作系统，用于企业级思科 Catalyst 9000 产品系列，支持模型驱动的可编程性和流传输遥感勘测</p>
                            <p></p>
                            <p>●ASIC 具有可编程渲染管线和微引擎功能，并且支持基于模板配置第 2 层和第 3 层转发、访问控制列表 (ACL) 和服务质量 (QoS) 条目的分配</p>
                            <p></p>
                            <p>特性和优势</p>
                            <p>平台详细信息</p>
                            <p></p>
                            <p>交换机型号和配置</p>
                            <p>
                                <table className="product_table1" border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="26.1000%" valign="center" className="table1_header1">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            型号
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="13.2000%" valign="center" className="table1_header2">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            FRU&nbsp;电源
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="13.2000%" valign="center" className="table1_header2">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            FRU&nbsp;风扇
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="11.6000%" valign="center" className="table1_header3">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            模块化上行链路
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="13.5800%" valign="center" className="table1_header4">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            堆叠带宽支持
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="22.3200%" valign="center" className="table1_header5">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            SD-Access&nbsp;支持
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="26.1000%" valign="bottom" className="table1_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        模块化上行链路型号&nbsp;(C9200&nbsp;SKU)
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="13.2000%" valign="bottom" className="table1_header2" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            ✓
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="13.2000%" valign="bottom" className="table1_header2" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            ✓
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="11.6000%" valign="bottom" className="table1_header3" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            ✓
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="13.5800%" valign="bottom" className="table1_header4" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            160&nbsp;Gbps
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="22.3200%" valign="bottom" className="table1_header5" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            是（4&nbsp;个虚拟网络）
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr><td width="26.1000%" valign="bottom" className="table1_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                            <p className="table1_header1_header01">
                                                <span className="table1_header1_headre01_header001">
                                                    非模块化上行链路型号&nbsp;(C9200L&nbsp;SKU)
                                                </span>
                                            </p>
                                        </td>
                                            <td width="13.2000%" valign="bottom" className="table1_header2" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            ✓
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="13.2000%" valign="bottom" className="table1_header2" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            ✗
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="11.6000%" valign="bottom" className="table1_header3" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            ✗
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="13.5800%" valign="bottom" className="table1_header4" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            80&nbsp;Gbps
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td width="22.3200%" valign="bottom" className="table1_header5" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            有限（1&nbsp;个虚拟网络）
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>思科Catalyst 9200 系列包括模块化 (C9200) 交换机型号和非模块化 (C9200L) 交换机型号。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt71221.png' />
                            </p>
                            <p>图 1.思科 Catalyst 9200 系列交换机</p>
                            <p>思科 Catalyst 9200 系列交换机配置</p>
                            <p>
                                <table className="product_table2" border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td valign="center" className="table2_header1">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            交换机型号
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="center" className="table2_header1">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            下行链路&nbsp;10/100/1000&nbsp;或&nbsp;PoE+&nbsp;铜缆端口总数
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="center" className="table2_header1">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            上行链路配置
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="center" className="table2_header1">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            标配主交流电源
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="center" className="table2_header1">
                                                <p className="table1_header1_header01">
                                                    <b>
                                                        <span className="table1_header1_headre01_header001">
                                                            风扇
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" colspan="5" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        模块化上行链路型号
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200-24T
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        24&nbsp;个数据端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        模块化上行链路选项
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-125WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        FRU&nbsp;冗余
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200-24P
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        24&nbsp;个满功率&nbsp;PoE+&nbsp;端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        模块化上行链路选项
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-600WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        FRU&nbsp;冗余
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200-48T
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        48&nbsp;个数据端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        模块化上行链路选项
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-125WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        FRU&nbsp;冗余
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200-48P
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        48&nbsp;个满功率&nbsp;PoE+&nbsp;端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        模块化上行链路选项
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-1KWAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        FRU&nbsp;冗余
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" colspan="5" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化上行链路型号
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-24T-4G
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        24&nbsp;个数据端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;1G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-125WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-24P-4G
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        24&nbsp;个满功率&nbsp;PoE+&nbsp;端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;1G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-600WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-48T-4G
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        48&nbsp;个数据端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;1G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-125WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-48P-4G
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        48&nbsp;个满功率&nbsp;PoE+&nbsp;端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;1G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-1KWAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-24T-4X
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        24&nbsp;个数据端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;10G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-125WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-24P-4X
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        24&nbsp;个满功率&nbsp;PoE+&nbsp;端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;10G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-600WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-48T-4X
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        48&nbsp;个数据端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;10G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-125WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-48P-4X
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        48&nbsp;个满功率&nbsp;PoE+&nbsp;端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;10G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-1KWAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-24PXG-4X
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        8&nbsp;个&nbsp;mGig&nbsp;端口，16&nbsp;个满功率&nbsp;PoE+&nbsp;端口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        4&nbsp;条&nbsp;10G&nbsp;非模块化上行链路
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        PWR-C5-600WAC
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        非模块化冗余
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">
                                                        C9200L-48PXG-4X
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">12&nbsp;个&nbsp;mGig&nbsp;端口，36&nbsp;个满功率&nbsp;PoE+&nbsp;端口</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">4&nbsp;条&nbsp;10G&nbsp;非模块化上行链路</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">PWR-C5-1KWAC</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">非模块化冗余</span>
                                                </p>
                                            </td></tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">C9200L-24PXG-2Y</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">8&nbsp;个&nbsp;mGig&nbsp;端口，16&nbsp;个满功率&nbsp;PoE+&nbsp;端口</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">2&nbsp;条&nbsp;25G&nbsp;非模块化上行链路</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">PWR-C5-600WAC</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "white" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">非模块化冗余</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">C9200L-48PXG-2Y</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">8&nbsp;个&nbsp;mGig&nbsp;端口，40&nbsp;个满功率&nbsp;PoE+&nbsp;端口</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">2&nbsp;条&nbsp;25G&nbsp;非模块化上行链路</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">PWR-C5-1KWAC</span>
                                                </p>
                                            </td>
                                            <td valign="bottom" className="table2_header1" style={{ backgroundColor: "rgb(242,242,242)" }}>
                                                <p className="table1_header1_header01">
                                                    <span className="table1_header1_headre01_header001">非模块化冗余</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>网络模块</p>
                            <p>思科 Catalyst 9200 系列交换机配备模块化或非模块化上行链路，如表 1 所示。模块化 SKU 的现场可更换网络模块支持从 1G 无中断迁移到 10G 及更高规格，因此可为您的基础设施投资提供投资保护。在购买交换机时，您可以选择表 2 中列出的网络模块。</p>
                            <p></p>
                            <p>智能 PoE+</p>
                            <p></p>
                            <p>●     IEEE 802.3at PoE+：思科 Catalyst 9200 系列交换机支持 IEEE 802.3at PoE+（每个端口功率最高 30W），以降低包含思科 IP 电话、思科 Aironet® 无线接入点或其他符合标准的 PoE+ 终端设备的总拥有成本。通过 PoE+，您无需为每个支持 PoE 的设备提供插墙式电源，也无需额外购置电缆和设计电路，而在传统 IP 电话和 WLAN 部署中，这些需求是无法避免的。思科 Catalyst 9200 系列交换机的 PoE+ 功率分配是动态进行的，功率映射最高可扩展至 1440W PoE+ 功率。</p>
                            <p></p>
                            <p>●     永久 PoE ：思科 Catalyst 9200 系列交换机支持永久 PoE，可在交换机重新加载期间维持 PoE+ 供电。这对医疗设备等重要终端以及 PoE 供电型照明系统等物联网 (IoT) 终端十分重要，可确保不会在交换机重新启动期间造成中断。</p>
                            <p></p>
                            <p>●     快速 PoE：当交换机恢复电源后，快速 PoE 无需等待操作系统完全加载即可开始为终端供电，因此可以加快终端启动的速度。</p>
                            <p></p>
                            <p>
                            <a href='https://www.cisco.com/c/zh_cn/products/wireless/catalyst-9100ax-access-points/index.html'>思科 Catalyst 9100 无线接入点</a>
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt89771.png' />
                            </p>
                            <p>Cisco® Catalyst® 9105 系列无线接入点是下一代企业</p>
                            <p>级无线接入点，具有灵活、安全可靠、智能省心的特点。</p>
                            <p></p>
                            <p>随着高密度网络和物联网的出现，我们比以往任何时候都更依赖于无线网络。每年都有越来越多的设备连接到网络，从高性能客户端设备到低带宽物联网设备举不胜举，使现有网络的网速日益受到挑战。Cisco Catalyst 9105 无线接入点可以随时随地为所有用户提供体验，在各种网络部署中发挥卓越的可扩展性和出色的性能。Catalyst 9105 无线接入点在支持 Wi-Fi 6 (802.11ax) 标准的基础上更进一步，能够实现安全性、恢复能力和运营灵活性的绝佳融合，并提供更强大的网络智能功能。</p>
                            <p></p>
                            <p>Catalyst 9105 无线接入点不仅可拓展思科基于意图的网络，而且可进行扩展以满足不断增长的物联网需求，并全面支持最新的创新成果和最新技术，因此适合用于各种规模的网络。Catalyst 9105 无线接入点的性能、安全性和分析功能均处于领先水平。根据安装方式，Catalyst 9105 分为两种型号：适用于天花板安装的 Catalyst 9105i 和适用于壁式安装的 Catalyst 9105w。凭借独特的安装设计，Catalyst 9105w 非常适合酒店、学生宿舍和其他多住户单元。</p>
                            <p></p>
                            <p>Catalyst 9105 无线接入点还可以为远程员工和微型办公室提供安全保护。所有 Cisco Aironet 或 Catalyst 无线接入点都可以作为 OfficeExtend 无线接入点 (OEAP) 使用。通过 OEAP，员工无需设置 VPN 或具备高深技术知识，即可在家中或临时的微型办公室中访问企业 SSID 和公司网络。</p>
                            <p></p>
                            <p>Cisco Catalyst 9105 系列无线接入点可结合思科全数字化网络架构 (Cisco DNA) 使用，是可满足您当前和未来需求的企业级产品。选用该系列无线接入点是实现网络更新换代的第一步，唯有如此，才能更充分地发挥 Wi-Fi 6 提供的所有功能和优势。</p>
                            <p></p>
                            <p>Catalyst 9105 系列还支持 Cisco DNA Center 的思科用户定义网络(Cisco User Defined Network)功能。通过该功能，IT 可以授权终端用户自行控制他们在共享网络中的个人无线网络分段。这样，终端用户就能在网络中安全地远程部署自己的设备。思科用户定义网络功能在确保设备安全和可控的前提下，允许所有用户自行选择能够连接其个人网络的用户，既有助于大学提高宿舍入住率，也有助于酒店增加客人逗留天数。（将于 2020 年下半年推出。）</p>
                            <p></p>
                            <p>特性和优势</p>
                            <p>
                                <table border="1" cellspacing="0" className="product_table2">
                                    <tbody>
                                        <tr>
                                            <td valign="top" className="table3_header1">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        特性
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        优势
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        Wi-Fi&nbsp;6&nbsp;(802.11ax)
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        IEEE&nbsp;802.11ax&nbsp;也称为高效无线&nbsp;(HEW)&nbsp;标准或&nbsp;Wi-Fi&nbsp;6，是在&nbsp;802.11ac&nbsp;的基础上制定的新兴标准。802.11ax&nbsp;在常规环境下提供更出色的无线上网体验，并为各种高级应用（例如&nbsp;4K&nbsp;或&nbsp;8K&nbsp;视频、高密度高清协作应用、无线网络全覆盖的办公室和物联网）提供更有保障的性能。不同于&nbsp;802.11ac&nbsp;标准，Wi-Fi&nbsp;6&nbsp;可同时使用&nbsp;2.4&nbsp;Ghz&nbsp;和&nbsp;5&nbsp;GHz&nbsp;频段。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        上行链路/下行链路&nbsp;OFDMA
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        基于&nbsp;OFDMA&nbsp;的调度功能可将带宽分割为更小的频率资源块进行分配，这些频率资源块又称为资源单位&nbsp;(RU)，可在下行链路和上行链路中将其分配给各个客户端，从而减少开销和延迟。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        上行链路/下行链路&nbsp;MU-MIMO&nbsp;技术
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MU-MIMO&nbsp;
                                                        支持双空间流，使得无线接入点可以在客户端设备之间拆分空间流，最大限度提高吞吐量。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        BSS&nbsp;着色
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        利用空间复用（也称为基本服务集&nbsp;[BSS]&nbsp;着色）功能，无线接入点及其客户端可以区分各种&nbsp;BSS，从而能够同步传输更多数据。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        目标唤醒时间
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        新增名为目标唤醒时间&nbsp;(TWT)&nbsp;的节能模式，允许客户端保持睡眠状态，仅在预先计划的（目标）时间唤醒，以便与无线接入点交换数据。与&nbsp;802.11n&nbsp;和&nbsp;802.11ac&nbsp;相比，此模式可以使电池供电型设备的节电能力提高&nbsp;3&nbsp;至&nbsp;4&nbsp;倍。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        智能采集
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        智能采集功能可探测网络，向&nbsp;Cisco&nbsp;DNA&nbsp;Center&nbsp;提供深度分析数据。这项软件功能可以跟踪&nbsp;240&nbsp;多种异常情况，并根据需要模仿现场网络管理员对所有数据包进行即时检查。借助智能采集功能，您可以对您的无线网络做出更有针对性的决策。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        蓝牙&nbsp;5
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        集成蓝牙低功耗&nbsp;(BLE)&nbsp;5&nbsp;无线电，可满足资产跟踪、路线导航或分析等基于位置的使用案例的需求。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        应用容器支持
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        提供容器支持，可使主机无线接入点上的物联网应用具备边缘计算功能。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        Apple&nbsp;功能
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        为了在基于思科技术的企业网络中为&nbsp;iOS&nbsp;设备提供移动体验，思科与&nbsp;Apple&nbsp;建立了合作关系。通过将&nbsp;Apple&nbsp;iOS&nbsp;的新功能与思科最新软硬件相结合，企业现在可以更有效地利用其网络基础设施，面向所有业务应用提供更出色的用户体验。
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        双方协作的核心是在思科&nbsp;WLAN&nbsp;和&nbsp;Apple&nbsp;设备之间采用独特的握手方式。通过这种握手，思科&nbsp;WLAN&nbsp;将能够向&nbsp;Apple&nbsp;设备提供出色的&nbsp;Wi-Fi&nbsp;漫游体验。而且，思科&nbsp;WLAN&nbsp;会对&nbsp;Apple&nbsp;设备建立信任，并优先处理由&nbsp;Apple&nbsp;设备指定的业务关键型应用。此功能也被称为快速通道功能。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>安全基础设施</p>
                            <p>采用思科信任锚技术打造的可靠系统为保障思科产品安全奠定坚实的基础。在 Cisco Catalyst 9100 无线接入点上，这些技术能硬件和软件的真实性，从而建立供应链信任，并有力防止针对软件和固件的中间人攻击。信任锚功能包括：</p>
                            <p>●映像签名</p>
                            <p>●安全引导</p>
                            <p>●思科信任锚模块</p>
                            <p>支持 Cisco DNA</p>
                            <p>Cisco Catalyst 9105 系列无线接入点与 Cisco DNA 相结合，可实现全面的网络转型。有了 Cisco DNA，您可以通过实时分析功能来真正做到对网络了如指掌，快速检测和遏制安全威胁，并通过自动化和可视化在全网络范围实现一致性。Cisco Catalyst 9105 系列无线接入点支持业界领先的思科企业架构：SD-Access（软件定义接入）。</p>
                            <p>Cisco Catalyst 9105 系列与 Cisco DNA 配合使用时，可提供如下功能：</p>
                            <p>●Cisco DNA Spaces</p>
                            <p>●思科身份服务引擎</p>
                            <p>●Cisco DNA 分析和网络状态感知</p>
                            <p>产品规格</p>
                            <p>
                                <table border="1" cellspacing="0" className="product_table2">
                                    <tbody>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header1">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        项目
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        规格
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        部件编号
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Cisco&nbsp;Catalyst&nbsp;9105AX&nbsp;无线接入点：室内环境，带内置天线
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;C9105AXI-x&nbsp;：&nbsp;Cisco&nbsp;Catalyst&nbsp;9105&nbsp;系列&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Cisco&nbsp;Catalyst&nbsp;9105AX&nbsp;无线接入点：墙壁固定板，带内置天线
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;C9105AXW-x&nbsp;：&nbsp;Cisco&nbsp;Catalyst&nbsp;9105&nbsp;系列&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            管制范围：（x&nbsp;=&nbsp;管制范围）
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        客户负责验证在其各自国家/地区的使用审批。要验证是否已获得批准，以及确定与特定国家/地区对应的管制范围，请访问：<a href="https://www.cisco.com/go/aironet/compliance">https://www.cisco.com/go/aironet/compliance</a>。
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        并非所有管制范围都已获审批。全球价目表和/或地区价目表中将会即时提供已获审批的部件号。
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            思科无线局域网服务
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;AS-WLAN-CNSLT&nbsp;：&nbsp;<a href="https://www.cisco.com/en/US/services/ps2961/ps6899/ps8306/services_datasheets_securewireless_scopeddesign_customer.pdf">思科无线局域网网络规划和设计服务</a>&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;AS-WLAN-CNSLT&nbsp;：&nbsp;<a href="https://www.cisco.com/en/US/services/ps2961/ps6899/ps8306/802-11n_Migration_SO_Final_0330.pdf">思科无线局域网&nbsp;802.11n&nbsp;迁移服务</a>&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;AS-WLAN-CNSLT&nbsp;：&nbsp;<a href="https://www.cisco.com/en/US/services/ps2961/ps6899/ps8306/Performance-Security_Assessment_SO_Final_0622.pdf">思科无线局域网性能和安全评估服务</a>&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        软件
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        Catalyst&nbsp;9105&nbsp;AXI&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;思科统一无线网络软件版本&nbsp;8.10.MR3&nbsp;或更高版本&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;Cisco&nbsp;IOS
                                                    </span>
                                                    <sup>
                                                        <span className="table3_span1">
                                                            ®
                                                        </span>
                                                    </sup>
                                                    <span className="table3_span1">
                                                        &nbsp;XE&nbsp;软件版本&nbsp;17.3.1&nbsp;或更高版本&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        Catalyst&nbsp;9105AXW&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;思科统一无线网络软件版本&nbsp;8.10.MR3&nbsp;或更高版本&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;Cisco&nbsp;IOS®&nbsp;XE&nbsp;软件版本&nbsp;17.3.1&nbsp;或更高版本&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        支持的无线局域网控制器
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;Cisco&nbsp;Catalyst&nbsp;9800&nbsp;系列无线控制器&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;思科&nbsp;3504&nbsp;、&nbsp;5520&nbsp;和&nbsp;8540&nbsp;无线控制器及思科虚拟无线控制器&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        802.11n&nbsp;版本&nbsp;2.0（及相关）功能
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;2x2&nbsp;MIMO&nbsp;，具有&nbsp;2&nbsp;个空间流&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;最大比合并&nbsp;(MRC)&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;802.11n&nbsp;和&nbsp;802.11a/g&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;20&nbsp;MHz&nbsp;和&nbsp;40&nbsp;MHz&nbsp;信道&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;PHY&nbsp;数据速率最高为&nbsp;444.4&nbsp;Mbps&nbsp;（&nbsp;5&nbsp;GHz&nbsp;的&nbsp;40&nbsp;MHz&nbsp;信道及&nbsp;2.4&nbsp;GHz&nbsp;的&nbsp;20&nbsp;MHz&nbsp;信道）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;数据包汇聚：汇聚&nbsp;MAC&nbsp;协议数据单元&nbsp;(A-MPDU)&nbsp;（传输和接收）、汇聚&nbsp;MAC&nbsp;服务数据单元&nbsp;(A-MPDU)<br />（传输和接收）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;802.11&nbsp;动态频率选择&nbsp;(DFS)&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;循环移动差分&nbsp;(CSD)&nbsp;支持&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        802.11ac&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;2x2&nbsp;下行链路&nbsp;MU-MIMO&nbsp;，具有&nbsp;2&nbsp;个空间流&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;最大比合并&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;802.11ac&nbsp;波束成形&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;20&nbsp;MHz&nbsp;、&nbsp;40&nbsp;MHz&nbsp;、&nbsp;80&nbsp;MHz&nbsp;信道&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;PHY&nbsp;数据速率最高为&nbsp;866.7&nbsp;Mbps&nbsp;（&nbsp;5&nbsp;GHz&nbsp;的&nbsp;80&nbsp;MHz&nbsp;信道）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;数据包汇聚：&nbsp;A-MPDU&nbsp;（发送和接收）、&nbsp;A-MSDU&nbsp;（发送和接收）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;802.11&nbsp;DFS&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;循环移动差分支持&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;WPA3&nbsp;支持&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        802.11ax
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;2x2&nbsp;上行链路&nbsp;/&nbsp;下行链路&nbsp;MU-MIMO&nbsp;，具有&nbsp;2&nbsp;个空间流&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;上行链路&nbsp;/&nbsp;下行链路&nbsp;OFDMA&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;TWT&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;BSS&nbsp;着色&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;最大比合并&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;802.11ax&nbsp;波束成形&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;20&nbsp;MHz&nbsp;、&nbsp;40&nbsp;MHz&nbsp;、&nbsp;80&nbsp;MHz&nbsp;信道&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;PHY&nbsp;数据速率最高为&nbsp;1.488&nbsp;Gbps&nbsp;（&nbsp;5&nbsp;GHz&nbsp;的&nbsp;40&nbsp;MHz&nbsp;信道及&nbsp;2.4&nbsp;GHz&nbsp;的&nbsp;20&nbsp;MHz&nbsp;信道）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;数据包汇聚：&nbsp;A-MPDU&nbsp;（发送和接收）、&nbsp;A-MSDU&nbsp;（发送和接收）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;802.11&nbsp;DFS&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;循环移动差分支持&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;WPA3&nbsp;支持&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        集成天线
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        Catalyst&nbsp;9105AXW&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;2.4&nbsp;GHz&nbsp;：峰值增益&nbsp;3&nbsp;dBi&nbsp;，内置天线，全向（方位角）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;5&nbsp;GHz&nbsp;：峰值增益&nbsp;5&nbsp;dBi&nbsp;，内置天线，全向（方位角）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        Catalyst&nbsp;9105AXI&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;2.4&nbsp;GHz&nbsp;：峰值增益&nbsp;4&nbsp;dBi&nbsp;，内置天线，全向（方位角）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;5&nbsp;GHz&nbsp;：峰值增益&nbsp;5&nbsp;dBi&nbsp;，内置天线，全向（方位角）&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        接口
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Catalyst&nbsp;9105AXW
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;1&nbsp;个&nbsp;100/1000/2500&nbsp;Base-T&nbsp;（以太网）上行链路接口&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;3&nbsp;个&nbsp;100/1000&nbsp;Base-T&nbsp;（以太网）下行链路接口（&nbsp;LAN1&nbsp;、&nbsp;LAN2&nbsp;和&nbsp;LAN3&nbsp;；&nbsp;LAN1&nbsp;支持&nbsp;10.5W&nbsp;PSE&nbsp;电源预算）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;管理控制台端口&nbsp;(RJ-45)&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;USB&nbsp;2.0&nbsp;(4.5W)&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;直通端口&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Catalyst&nbsp;9105AXI
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;1&nbsp;个&nbsp;100/1000&nbsp;Base-T&nbsp;（以太网）上行链路接口&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;管理控制台端口&nbsp;(RJ-45)&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        指示灯
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;状态&nbsp;LED&nbsp;指示引导加载程序状态、关联状态、工作状态、引导加载程序警告、引导加载程序错误&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        尺寸（宽&nbsp;x&nbsp;长&nbsp;x&nbsp;高）
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;无线接入点（不含安装支架）：&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            C9105AXW
                                                        </span>
                                                    </b>
                                                    <span className="table3_span1">
                                                        ：3.54&nbsp;x&nbsp;6.3&nbsp;x&nbsp;1.3&nbsp;英寸（90&nbsp;x&nbsp;160&nbsp;x&nbsp;32.66&nbsp;毫米）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            C9105AXI
                                                        </span>
                                                    </b>
                                                    <span className="table3_span1">
                                                        ：5.9&nbsp;x&nbsp;5.9&nbsp;x&nbsp;1.18&nbsp;英寸（150&nbsp;x&nbsp;150&nbsp;x&nbsp;30&nbsp;毫米）
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        重量
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Cisco&nbsp;Catalyst&nbsp;9105AXW
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;0.8&nbsp;磅（&nbsp;373.2&nbsp;克）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Cisco&nbsp;Catalyst&nbsp;9105AXI
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;0.7&nbsp;磅（&nbsp;329.5&nbsp;克）&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" rowspan="8" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        输入功率要求
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;802.3at&nbsp;增强型以太网供电&nbsp;(PoE+)&nbsp;，思科通用型以太网供电（思科&nbsp;UPOE
                                                    </span>
                                                    <sup>
                                                        <span className="table3_span1">
                                                            ®
                                                        </span>
                                                    </sup>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;思科馈电器&nbsp;AIR-PWRINJ6=&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;802.3af&nbsp;PoE&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;思科馈电器&nbsp;AIR-PWRINJ5=&nbsp;（注意：此馈电器仅支持&nbsp;802.3af&nbsp;）&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Catalyst&nbsp;9105AXI
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="6" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            PoE&nbsp;电源
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="5" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            2.4&nbsp;GHz&nbsp;无线电
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            5&nbsp;GHz&nbsp;无线电
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="5" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            链路速度
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="6" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        802.3af&nbsp;(PoE)&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="5" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2x2&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2x2&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="5" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1G&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Catalyst&nbsp;9105AXW
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            PoE&nbsp;电源
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            2.4&nbsp;GHz&nbsp;无线电
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            5&nbsp;GHz&nbsp;无线电
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            链路速度
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            USB
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            LAN1&nbsp;PSE
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        802.3af&nbsp;(PoE)&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2x2&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2x2&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2.5G&nbsp;mGig&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        禁用&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        禁用&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        802.3at&nbsp;(PoE+)&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2x2&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2x2&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2.5G&nbsp;mGig&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="6" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        启用&nbsp;USB&nbsp;或&nbsp;LAN1&nbsp;PSE&nbsp;。&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        环境参数
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Cisco&nbsp;Catalyst&nbsp;9105AXW
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;非工作（存储）温度：&nbsp;-22&nbsp;°&nbsp;F&nbsp;至&nbsp;158&nbsp;°&nbsp;F&nbsp;（&nbsp;-30&nbsp;°&nbsp;C&nbsp;至&nbsp;70&nbsp;°&nbsp;C&nbsp;）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;非工作（存储）海拔试验：&nbsp;25&nbsp;˚&nbsp;C&nbsp;，&nbsp;15,000&nbsp;英尺（&nbsp;4600&nbsp;米）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;工作温度：&nbsp;32&nbsp;°&nbsp;F&nbsp;至&nbsp;122&nbsp;°&nbsp;F&nbsp;（&nbsp;0&nbsp;°&nbsp;C&nbsp;至&nbsp;50&nbsp;°&nbsp;C&nbsp;）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;工作湿度：&nbsp;10%&nbsp;至&nbsp;90%&nbsp;（非冷凝）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;工作海拔试验：&nbsp;40&nbsp;˚&nbsp;C&nbsp;，&nbsp;9843&nbsp;英尺（&nbsp;3000&nbsp;米）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        注：当环境工作温度超过&nbsp;40°C&nbsp;时，无线接入点在&nbsp;2.4&nbsp;GHz&nbsp;无线电频段上会从&nbsp;2x2&nbsp;模式自动切换为&nbsp;1x1&nbsp;模式，且&nbsp;USB&nbsp;接口和&nbsp;LAN1&nbsp;PSE&nbsp;都将禁用。
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            Cisco&nbsp;Catalyst&nbsp;9105AXI
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;非工作（存储）温度：&nbsp;-22&nbsp;°&nbsp;F&nbsp;至&nbsp;158&nbsp;°&nbsp;F&nbsp;（&nbsp;-30&nbsp;°&nbsp;C&nbsp;至&nbsp;70&nbsp;°&nbsp;C&nbsp;）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;非工作（存储）海拔试验：&nbsp;25&nbsp;˚&nbsp;C&nbsp;，&nbsp;15,000&nbsp;英尺（&nbsp;4600&nbsp;米）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;工作温度：&nbsp;32&nbsp;°&nbsp;F&nbsp;至&nbsp;122&nbsp;°&nbsp;F&nbsp;（&nbsp;0&nbsp;°&nbsp;C&nbsp;至&nbsp;50&nbsp;°&nbsp;C&nbsp;）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;工作湿度：&nbsp;10%&nbsp;至&nbsp;90%&nbsp;（非冷凝）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;工作海拔试验：&nbsp;40&nbsp;˚&nbsp;C&nbsp;，&nbsp;9843&nbsp;英尺（&nbsp;3000&nbsp;米）&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        注：当环境工作温度超过&nbsp;40°C&nbsp;时，无线接入点在&nbsp;2.4&nbsp;GHz&nbsp;无线电频段上会从&nbsp;2x2&nbsp;模式自动切换为&nbsp;1x1&nbsp;模式。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        可用发射功率设置（最大值/最小值）
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="11" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            2.4&nbsp;GHz
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20&nbsp;dBm&nbsp;(100&nbsp;mW)&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -7&nbsp;dBm&nbsp;(0.2&nbsp;mW)
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="9" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            5&nbsp;GHz
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20&nbsp;dBm&nbsp;(100&nbsp;mW)&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -7&nbsp;dBm&nbsp;(0.2&nbsp;mW)
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        管制范围&nbsp;
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        注：
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            客户应负责验证所在国家/地区是否批准使用此产品。要验证是否已获得批准，以及确定与特定国家/地区对应的管制范围，请访问：
                                                        </span>
                                                    </b>
                                                    <span className="table3_span1">
                                                        <a href="https://www.cisco.com/go/aironet/compliance">https://www.cisco.com/go/aironet/compliance</a>
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        有关管制范围支持情况的更多信息，请参阅<a href="https://www.cisco.com/c/dam/en/us/products/collateral/wireless/access-points/sales-tool-c96-744090.pdf">思科管制范围白皮书</a>。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        合规性
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="10" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            •&nbsp;&nbsp;&nbsp;安全：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IEC&nbsp;60950-1&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;60950-1&nbsp;
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;UL&nbsp;60950-1
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IEC&nbsp;62368-1
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;62368-1
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;UL/CUL&nbsp;62368-1
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CAN/CSA-C22.2&nbsp;No.&nbsp;60950-1
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AS/NZS60950.1
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;UL&nbsp;2043&nbsp;–&nbsp;仅限&nbsp;C9105AXI
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;III&nbsp;类设备
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            •&nbsp;&nbsp;&nbsp;排放：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CISPR&nbsp;32（2015&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;55032（2012&nbsp;年修订版/AC:2013）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;55032（2015&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN61000-3-2（2014&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN61000-3-3（2013&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KN61000-3-2
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KN61000-3-3
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AS/NZS&nbsp;CISPR&nbsp;32&nbsp;B&nbsp;类（2015&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;47&nbsp;CFR&nbsp;FCC&nbsp;第&nbsp;15B&nbsp;部分
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ICES-003（2016&nbsp;年第&nbsp;6&nbsp;版修订版，B&nbsp;类）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VCCI&nbsp;A-CISPR&nbsp;32
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CNS（13438&nbsp;修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KN-32
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QCVN&nbsp;118:2018/BTTTT
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            抗扰性：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CISPR&nbsp;24（2010&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;55024&nbsp;+&nbsp;AMD&nbsp;1（2010&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;55035：2017
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KN35
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            排放与抗扰性：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;301&nbsp;489-1&nbsp;(v2.1.1&nbsp;2017-02)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;301&nbsp;489-17&nbsp;(v3.1.1&nbsp;2017-02)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QCVN&nbsp;(18:2014)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QCVN&nbsp;112:2017/BTTTT
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KN&nbsp;489-1
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KN&nbsp;489-17
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;60601-1-2:2015
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;61000-6-1:&nbsp;2007
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="10" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            无线电：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;300&nbsp;328&nbsp;(v2.1.1)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;301&nbsp;893&nbsp;(v2.1.1)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AS/NZS&nbsp;4268（2017&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;47&nbsp;CFR&nbsp;FCC&nbsp;第&nbsp;15C、15.247、15.407&nbsp;部分
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RSP-100
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RSS-GEN
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RSS-247
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国国家无线电管理委员会&nbsp;(SRRC)&nbsp;认证
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LP0002（2018.1.10&nbsp;修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日本标准33a、标准66&nbsp;和标准71
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            射频安全：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;50385&nbsp;（2002&nbsp;年&nbsp;8&nbsp;月修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ARPANSA
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AS/NZS&nbsp;2772（2016&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;62209-1（2016&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;62209-2（2010&nbsp;年修订版）
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;47&nbsp;CFR&nbsp;第&nbsp;1.1310&nbsp;和&nbsp;2.1091&nbsp;部分
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RSS-102
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            IEEE&nbsp;标准：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IEEE&nbsp;802.3
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IEEE&nbsp;802.3ab
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IEEE&nbsp;802.3af/at
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IEEE&nbsp;802.11a/b/g/n/ac/ax
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IEEE&nbsp;802.11h、802.11d
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            安全性：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;802.11i、Wi-Fi&nbsp;保护访问&nbsp;2&nbsp;(WPA2)、WPA3
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;802.1X
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;高级加密标准&nbsp;(AES)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <b>
                                                        <span className="table3_span1">
                                                            可扩展鉴权协议(EAP)&nbsp;类型：
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EAP-传输层安全&nbsp;(TLS)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EAP-Tunneled&nbsp;TLS&nbsp;(TTLS)&nbsp;或&nbsp;Microsoft&nbsp;质询握手身份验证协议&nbsp;(MSCHAP)&nbsp;v2
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;受保护的&nbsp;EAP&nbsp;(PEAP)&nbsp;v0&nbsp;或&nbsp;EAP-MSCHAP&nbsp;v2
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EAP-通过安全隧道灵活身份验证&nbsp;(EAP-FAST)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PEAP&nbsp;v1&nbsp;或&nbsp;EAP-通用令牌卡&nbsp;(GTC)
                                                    </span>
                                                </p>
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        ◦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EAP-客户身份识别卡&nbsp;(SIM)
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="2" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        数据速率/发射功率/接收灵敏度
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="20" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        •&nbsp;&nbsp;&nbsp;有关数据速率、发射功率和接收灵敏度的详细信息，请参阅&nbsp;<a href="https://www.cisco.com/c/zh_cn/support/wireless/catalyst-9100ax-access-points/products-installation-guides-list.html?cachemode=refresh">思科射频规格信息</a>。
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="22" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            发射功率和接收灵敏度
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            &nbsp;
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            &nbsp;
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            &nbsp;
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="9" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            5&nbsp;GHz&nbsp;无线电
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="7" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            2.4&nbsp;GHz&nbsp;无线电
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            &nbsp;
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            空间流
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            有源天线
                                                        </span>
                                                    </b>
                                                </p>
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            数量
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="5" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            总发射功率&nbsp;(dBm)
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            接收灵敏度(dBm)
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            总发射功率(dBm)
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            接收灵敏度(dBm)
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11/11b
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1&nbsp;Mbps
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        –
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        –
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -99
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        11&nbsp;Mbps
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        –
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        –
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -91
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11a/g
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        6&nbsp;Mbps
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -97
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -93
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        24&nbsp;Mbps
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -89
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -86
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        54&nbsp;Mbps
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -80
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -78
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11n&nbsp;HT20
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -96
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -93
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS15
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -75
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -73
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11n&nbsp;HT40
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -93
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS15
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -72
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11ac&nbsp;VHT20
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -96
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS8
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -74
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -94
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS8
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -71
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS9
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">-
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">-
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11ac&nbsp;VHT40
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -93
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS9
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -69
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -91
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS9
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -66
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11ac&nbsp;VHT80
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -90
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS9
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -66
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -88
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS9
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -63
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11ax&nbsp;HE20
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -96
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -93
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS11
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        19
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -66
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        19
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -64
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -95
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -91
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS11
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        19
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -65
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        19
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -63
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11ax&nbsp;HE40
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -93
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS11
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        19
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -63
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -92
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS11
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        19
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -62
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" colspan="21" className="table3_header3">
                                                <p className="table3_p1">
                                                    <b>
                                                        <span className="table3_span1">
                                                            802.11ax&nbsp;HE80
                                                        </span>
                                                    </b>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -90
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header2">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header2">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS11
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">1
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        19
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -60
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS0
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        20
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -89
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" className="table3_header3">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        MCS11
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="2" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">2
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        19
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -59
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="4" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" colspan="3" className="table3_header4">
                                                <p className="table3_p1">
                                                    <span className="table3_span1">
                                                        -
                                                    </span>
                                                </p>
                                            </td>
                                            <td valign="top" className="table3_header4">
                                                <p className="table3_p1">

                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 1 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="wxxyx" name="wxxyx" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                LENOVO
                            </p>
                            <p className="lstzz_ch">
                                LENOVO系列
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>ThinkSystem SR650</p>
                            <p>对于大中型企业以及受管的云服务提供商而言，Lenovo ThinkSystem SR650是2U双插槽服务器，也是全球使用范围最广的服务器类型。凭借205W CPU、低延迟NVMe硬盘和大功率GPU，可实现卓越的性能。</p>
                            <p>高度灵活且可配置的SR650拥有Lenovo经年累月建立起来的可靠性，是超融合基础架构（HCI）或软件定义的存储（SDS）的理想平台。</p>
                            <p>奠定了坚实的基础</p>
                            <p>1)利用验证的混合云设计将物理资源转化成服务，2）利用验证的大数据设计分析流数据，以及利用验证的OLTP数据库提高虚拟化事务系统的生产力。</p>
                            <p></p>
                            <p>功能全面且可扩展</p>
                            <p>ThinkSystem SR650支持英特尔“傲腾”数据中心级持久内存和多达两个第二代英特尔至强可扩展处理器铂金系列，整体性能比上一代产品提高36%。它支持两个300W高性能GPU和具有共享管理功能的ML2 NIC适配器。独特的Lenovo AnyBay技术可在相同的硬盘托架内灵活混搭SAS/SATA HDD/SSD和NVMe SSD。现可为24个NVMe驱动器提供支持。</p>
                            <p></p>
                            <p>主板上的四个直连NVMe端口利用NVMe驱动器提供超快的读/写速度，同时无需使用PCIe交换机适配器，因而降低了成本。</p>
                            <p></p>
                            <p>此外，还可以对存储进行分层来提高应用程序，打造最具效益的解决方案。可选的镜像M.2硬盘确保可靠性并快速完成OS初始化。SR650还带有80PLUS白金电源和钛金电源，并能在45°C的环境中持续运作，从而减少能耗。</p>
                            <p></p>
                            <p>安全且易于管理</p>
                            <p>Lenovo Thinkshield是一款全面的端到端安全方法，从开发阶段开始，一直贯穿于供应链和设备的整个生命周期。</p>
                            <p>Lenovo XClarity Controller设计了整洁的图形用户界面，符合Redfish要求的行业标准REST API，启动时间只有前一代服务器的一半，固件更新的速度要高出6倍之多。Lenovo XClarity Administrator是一款虚拟化应用程序，可集中管理ThinkSystem服务器、存储、网络。通过可重复使用的模式和策略，它可提升并扩展基础架构配置和维护。它可作为中心集成点，将数据中心流程扩展到物理IT。</p>
                            <p>在内部IT应用程序中运行XClarity Integrator，或者通过REST API进行集成，有助于进一步加快服务器配置，简化IT管理并控制成本。</p>
                            <p></p>
                            <p>规格：</p>
                            <p>
                                <table bordercolor="#fff" border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        外形/高度
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        2U机架式服务器
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        多达2个第二代英特尔至强可扩展处理器铂金系列，最高205W
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        24个DIMM插槽中多达9TB，使用128GB&nbsp;DIMM和英特尔傲腾数据中心级持久内存
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        扩展插槽
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        8个可选的PCIe3.0C插槽，包含1个适用于RAID适配器的专用PCIe插槽，可通过NVME交换机适配器扩展到2个PCIe3.0
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        驱动器托架
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持热插拔硬盘，3.5英寸硬盘最多到14个，或2.5英寸硬盘最多到24个（其中最多可支持12个AnyBay，或其中最多可支持24个NVMe）；另可支持2个M.2的驱动器（可支持RAID1）
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        HBA/RAID支持
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        邮件RAID（最多24个端口），带有内存缓存，最多16端口HBA
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        安全性与可用性特征
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        TPM1.2/2.0；PFA；热插拔/冗余驱动器，风扇和电源；45°温度下持续运行；光通路诊断LED；
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        网络接口
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        2/4端口1GbE&nbsp;LOM；2/4端口10GbE&nbsp;LOM(Base-T或SFP+);1个专用1GbE管理端口
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        电源（符合Energy&nbsp;Star&nbsp;2.0规范）
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        2个热插拔/冗余；550W/750W/1100W/1600W&nbsp;80&nbsp;PLU白金电源；或750W&nbsp;80&nbsp;PLUS钛金电源；
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        系统管理
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        XClarity&nbsp;Controller&nbsp;嵌入式管理，XClartiy&nbsp;Administrator集中基础架构交付
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        XClartiy&nbsp;Integrator插件以及XClarity&nbsp;Energy&nbsp;Manager集中服务器电源管理
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持的操作系统
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        Microsoft、Red&nbsp;Hat、SUSE、VMware
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        有限保修
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        3年7x24x4全国上门有限保修服务及安装服务
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>ThinkSystem SR860</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt25657.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt22484.png' />
                            </p>
                            <p>速度可可靠性</p>
                            <p>Lenovo ThinkSystem SR860为您提供今日所需的非凡性价比以及速度与可靠性，还为您带来明天所需的可扩展性与多功能性。SR860可运行从业务整合到数据库虚拟化，数据分析和科学/技术在内的广泛解决方案。</p>
                            <p>借助XClarity实现简单标准化的集成管理，配置时间比手动操作缩短95%.ThinkShield保护您的业务，从开发到运行，一路为您保驾护航。</p>
                            <p></p>
                            <p>多功能性</p>
                            <p>ThinkSystem SR860的敏捷设计提供相当大的配置灵活性。通过客户可安装的夹层托架，将第二代英特尔至强可扩展处理器铂金系列从两个扩展到四个，从而轻松快捷地完成处理器和内存“按需付费“升级，同时整体性能比第一代产品提高达36%。</p>
                            <p></p>
                            <p>工作负载优化的支持</p>
                            <p>英特尔傲腾数据中心级持久内存交付全新的灵活内存层，专为数据中心工作负载设计，提供无与伦比的高容量、可承受的成本和数据持久性。这一技术对现实中的数据中心运营有着重要影响：重启时间从数分钟缩短至数秒，虚拟机高密度提高1.2倍，延迟缩短14倍，IOPS提高14倍，显著加快了数据复制，并通过将持久数据构建到硬件中实现了更高的安全性。</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        外形/高度
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        4U机架式服务器
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        2&nbsp;颗或&nbsp;4&nbsp;颗第二代英特尔®至强®可扩展处理器铂金系列，最高 205W
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存（最大）
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存（最大）
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        48&nbsp;个插槽中最高&nbsp;6TB，使用&nbsp;128GB&nbsp;DIMM；
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        2666MHz&nbsp;/&nbsp;2933MHz&nbsp;（计划支持）TruDDR4
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        硬盘
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内部硬盘（总计/热插拔）
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内部存储（总计/热插拔）
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最多&nbsp;24&nbsp;个支持&nbsp;SAS/SATA&nbsp;HDD&nbsp;和&nbsp;SSD&nbsp;的&nbsp;2.5&nbsp;英寸存储托架，或&nbsp;8&nbsp;个&nbsp;2.5&nbsp;英寸&nbsp;NVMe&nbsp;SSD；以及&nbsp;2&nbsp;个支持启动的镜像&nbsp;M.2
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        网络
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        网络接口
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        网络接口
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        提供&nbsp;1GbE、10GbE、25GbE、32GbE、40GbE&nbsp;或&nbsp;InfiniBand&nbsp;PCIe&nbsp;适配器的多个选件；
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        1&nbsp;个（2/4&nbsp;端口）1GbE&nbsp;或&nbsp;10GbE&nbsp;LOM&nbsp;卡
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        电源
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        能效
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        能效2 个热插拔/冗余：750W/1100W/1600W/2000 AC 80 PLUS 铂金电源
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        安全性与可用性特征
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        TPM1.2/2.0；PFA；热插拔/冗余驱动器电源；冗余风扇；内部光路诊断 LED；通过专用 USB 端口进行前端接入诊断；可选的诊断 LCD 面板
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        RAID
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        RAID 支持
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        硬件 RAID（最多 16 个端口），带有闪存缓存；
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最多 16 端口 HBA
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        电源（符合Energy&nbsp;Star&nbsp;2.0规范）
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        2个热插拔/冗余；550W/750W/1100W/1600W&nbsp;80&nbsp;PLU白金电源；或750W&nbsp;80&nbsp;PLUS钛金电源；
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        管理性
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        系统管理
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        XClarity&nbsp;Controller&nbsp;嵌入式管理，
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        XClartiy&nbsp;Administrator集中基础架构交付
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        XClartiy&nbsp;Integrator插件以及XClarity&nbsp;Energy&nbsp;Manager集中服务器电源管理
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        操作系统
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持的操作系统
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        Microsoft、Red&nbsp;Hat、SUSE、VMware
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        GPU
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        GPU 支持
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        多达 2 个 GPU
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        扩展和性能
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大支持 13个PCIe，包括1个LOM 接口；可选的1个 ML2 接口
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        有限保修
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        3年7x24x4全国上门有限保修服务及安装服务
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>ThinkSystem DE系列全闪存阵列</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt23838.png' />
                            </p>
                            <p>迎接挑战</p>
                            <p>确保关键企业应用以最高的效率运行至关重要，因为这些应用会直接影响上市时间、收入和客户满意度。因此，数据中心正想方设法来提高负责控制其关键任务企业运营的应用的运行速度和响应速度。</p>
                            <p></p>
                            <p>帮助您的企业从竞争中脱颖而出并加快上市时间的一种方法是直接从多种混合工作负载环境中快速而可靠地获取价值和洞察力。</p>
                            <p></p>
                            <p>解决方案</p>
                            <p>紧凑型2U Lenovo ThinkSystem DE6000F全闪存（SSD）中端存储阵列将大大加快数据访问速度，进而强加数据价值。</p>
                            <p></p>
                            <p>这种全闪存阵列将行业领先而且在企业中经过了广泛验证的可用性特性与高达100万的IOPS、不到100微秒的响应时间和高达21GBps的读取带宽相结合。</p>
                            <p></p>
                            <p>同样，入门级Lenovo ThinkSystem DE4000F全闪存存储系统可在2U的狭小空间内加快数据访问速度，带来更高价值。</p>
                            <p></p>
                            <p>它也集企业公认的可用性特性、成本低廉的IOPS、不到100微秒的响应时间和最高10GBps的读取带宽于一身。</p>
                            <p></p>
                            <p>ThinkSystem DE6000F和DE4000F的可用性特性包括：</p>
                            <p></p>
                            <p>支持自动故障切换的冗余组件。</p>
                            <p></p>
                            <p>提供全面微调功能的直观存储管理。</p>
                            <p></p>
                            <p>高级监控和诊断，支持积极主动的维修。</p>
                            <p></p>
                            <p>用于保护数据的快照拷贝创建、卷拷贝和异步镜像。作为一种额外的特性，DE6000F还提供同步镜像。</p>
                            <p></p>
                            <p>这两种存储子系统都可以优化性价比、配置灵活性和操作简便性。它们使您能够以更快的速度和更全面的洞察力来处理关键任务数据，实现更有效的决策。</p>
                            <p></p>
                            <p>全闪存带来出色性能</p>
                            <p></p>
                            <p>DE6000F交付100万次的可持续IOPS性能和微秒级的响应时间，还可以提供高达21GBps的读取吞吐量，足以支持哪怕最为带宽密集型的工作负载。</p>
                            <p></p>
                            <p>另一方面，入门级DE4000F可以提供超过30万次的可持续IOPS性能和微秒级的响应时间。它可以提供高达10GBps的读取吞吐量，足以支持大多数其它作业。</p>
                            <p></p>
                            <p>为了保护您的存储网络投资，DE全闪存系列可以支持多种高速主机接口。DE6000F和DE4000F支持16Gb / 32Gb光纤通道、10Gb / 25Gb iSCSI和12Gb SAS。</p>
                            <p></p>
                            <p>DE全闪存系列可交付超过2,000个15k rpm HDD的性能，而机架空间、电源和冷却要求只有2%。通过将所需空间和电源减少98%，DE系列可以大幅度提高您的IT运营的总体效率，同时帮助达到性能要求。</p>
                            <p></p>
                            <p>巩固竞争优势</p>
                            <p></p>
                            <p>动态驱动器池（DDP）技术可以帮助存储管理员简化RAID管理，改进数据保护并在各种情况下确保可预测的性能。</p>
                            <p></p>
                            <p>这种创新的技术可以最大限度地减小驱动器故障带来的性能影响，而且与传统RAID相比，可在八分之一的时间内使系统恢复到运行状态。</p>
                            <p></p>
                            <p>凭借更短的重建（rebuild）时间和获得专利的重建优先级分配技术，DDP功能可以大大降低出现多起磁盘故障的风险，提供传统RAID望尘莫及的数据保护功能。</p>
                            <p></p>
                            <p>使用DE系列时，所有管理任务都可以在存储保持在线的情况下完成，同时可确保全面的读/写数据接入。存储管理员可以进行配置修改、进行维护或扩展存储容量，而不中断与相连主机的I/O操作。</p>
                            <p></p>
                            <p>DE系列的平滑管理特性包括：</p>
                            <p></p>
                            <p>动态卷扩展。</p>
                            <p>动态分段大小（segment size）迁移。</p>
                            <p>动态RAID级迁移。</p>
                            <p>固件更新。</p>
                            <p></p>
                            <p>DE系列全闪存阵列可利用多种高级数据保护特性，在本地或从远程防止数据丢失和故障停机，包括：</p>
                            <p></p>
                            <p>快照/卷拷贝。</p>
                            <p>异步镜像。</p>
                            <p>同步镜像（仅DE6000F）。</p>
                            <p>全面的驱动器加密。</p>
                            <p>最终，所有驱动器都会重新部署、淘汰或进行维护。在这一过程中，您希望自己的敏感数据不会连同驱动器一起离开贵公司。本地密钥管理和驱动器级加密相得益彰，使您能够全面保护静止数据的安全性而不影响性能。</p>
                            <p></p>
                            <p>XClarity支持</p>
                            <p>Lenovo XClarity Administrator是一种集中化资源管理解决方案，旨在降低联想服务器系统和解决方案的复杂性，加快响应速度并提高可用性。</p>
                            <p>Lenovo XClarity Administrator可以为我们的服务器、存储、网络交换机、超融合及ThinkAgile解决方案提供无代理硬件管理功能。</p>
                            <p>利用简化的管理控制面板，可以轻松实现以下功能：</p>
                            <p>明细记录。</p>
                            <p>监控。</p>
                            <p>固件更新</p>
                            <p></p>
                            <p></p>
                            <p>高密度服务器</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt25657.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt25660.png' />
                            </p>
                            <p>处理器</p>
                            <p>处理器</p>
                            <p>最多 2 个第二代英特尔®至强® 可扩展处理器铂金系列，最高 205W</p>
                            <p>操作系统</p>
                            <p>支持操作系统</p>
                            <p>Microsoft、Red Hat、SUSE、VMware</p>
                            <p>外形/高度</p>
                            <p>外形/高度</p>
                            <p>2U 机架机箱；4 个独立的计算节点</p>
                            <p>内存</p>
                            <p>内存</p>
                            <p>16 个插槽最大 2TB，使用 128GB DIMM</p>
                            <p>2,933MHz TruDDR4</p>
                            <p>RAID</p>
                            <p>RAID 支持</p>
                            <p>SW RAID 支持 JBOD</p>
                            <p>入门级 RAID</p>
                            <p>可选得硬件 RAID 12Gb 支持 JBOD</p>
                            <p>以及 M.2 引导支持与可选的 RAID</p>
                            <p>硬盘</p>
                            <p>驱动器托架（总数/热插拔）</p>
                            <p>最多 24 个（每个节点 6 个）热插拔 2.5 英寸 SAS/SATA HDD/SSD</p>
                            <p>最多 16 个（每个节点 4 个）热插拔NVMe SSD</p>
                            <p>电源</p>
                            <p>电源</p>
                            <p>2 个热插拔/冗余 1+1 1600W/2000W</p>
                            <p>或 2 个非冗余 1100W</p>
                            <p>扩展性</p>
                            <p>扩展插槽</p>
                            <p>每个 D2 机箱 1 个接驳装置：x8 PCIe 接驳装置，带有 8 个 PCIe 3.0 x8 插槽（每节点 2 个）</p>
                            <p>或者 x16 PCIe 接驳装置，带有 4 个 PCIe 3.0 x16 插槽（每节点 1 个）</p>
                            <p>最多 2 个外部 1U 托架（每个节点最多 2 个 GPU）</p>
                            <p>管理性</p>
                            <p>系统管理</p>
                            <p>XClarity Controller 嵌入式管理</p>
                            <p>XClarity Administrator 集中基础架构交付</p>
                            <p>XClarity Integrator 插件以及 XClarity Energy Manager 集中服务器电源</p>
                            <p>网络</p>
                            <p>网络接口</p>
                            <p>可选的 8 端口 EIOM 10Gb SFP+（每节点 2 个端口）</p>
                            <p>可选的 8 端口 EIOM 10GbaseT RJ45（每节点 2 个端口）</p>
                            <p>GPU</p>
                            <p>GPU 支持</p>
                            <p>GPU 托架支持一个或两个 GPU 卡</p>
                            <p>每个 D2 机柜最多两个 GPU 托架</p>
                            <p>热插拔组件</p>
                            <p>热插拔组件</p>
                            <p>电源，风扇，SAS/SATA/NVMe 存储设备</p>
                            <p>热插拔计算节点</p>
                            <p>服务</p>
                            <p>有限保修</p>
                            <p>3年7*24*4全国上门有限保修服务</p>
                            <p></p>
                            <p></p>
                            <p>边缘计算</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt26539.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt26542.png' />
                            </p>
                            <p></p>
                            <p>1 插槽 英特尔®至强® D-2100，最多 16 个内核</p>
                            <p>操作系统</p>
                            <p>操作系统</p>
                            <p>Microsoft Windows Server、SLES、Ubuntu、RHEL、VMware ESXi、vSAN、Scale Computing HC3。</p>
                            <p>外形/高度</p>
                            <p>外形/高度</p>
                            <p>1U 高、半宽边缘服务器；高度：40 毫米，宽度：215 毫米，深度：376 毫米</p>
                            <p>内存</p>
                            <p>内存</p>
                            <p>4 个插槽中最多 256GB，使用 64GB DIMM；2133/2400/2666MHz TruDDR4</p>
                            <p>RAID</p>
                            <p>RAID 支持</p>
                            <p>• 提供RAID支持</p>
                            <p>• 硬件 RAID 0/1 for M.2 SATA 开机 SSDs</p>
                            <p>• 硬件 RAID 0/1 for M.2 SATA 存储 SSDs</p>
                            <p>内部存储器</p>
                            <p>内部存储器</p>
                            <p>• 2个M.2 2280 SATA启动驱动器 + 8个M.2 22110 NVMe数据存储驱动器</p>
                            <p>• 2个M.2 2280 SATA启动驱动器 + 4个M.2 22110 NVMe/SATA数据存储驱动器</p>
                            <p>• 高温，高容量和高耐久性驱动器选项</p>
                            <p>电源</p>
                            <p>功耗</p>
                            <p>双冗余外部电源 100-240V AC</p>
                            <p>单一直流电源：-48VDC（-40VDC 至 -72VDC）</p>
                            <p>扩展性</p>
                            <p>扩展性能</p>
                            <p>• 1个PCIe 3.0 x16 75W或4个M.2 22110</p>
                            <p>• 支持1个用于AI推理的NVIDIA® T4 GPU</p>
                            <p>• 支持GPU、FGPA和ASIC加速器</p>
                            <p>• 支持运营技术网络</p>
                            <p>• 1个PCIe网卡扩展</p>
                            <p>o 4 个 1GbE RJ45 PCIe</p>
                            <p>o 2 个 10GBASE-T PCIe</p>
                            <p>o 2 个 10/25GbE SFP28 PCIe</p>
                            <p>I/O扩展</p>
                            <p>• 前端：1个VGA、2个USB 3.0、1个XClarity Controller管理迷你USB</p>
                            <p>• 后端：1个RJ45控制台串口、2个USB 2.0</p>
                            <p>• USB和控制台端口可禁用</p>
                            <p>管理性</p>
                            <p>系统管理</p>
                            <p>Lenovo XClarity Administrator，带移动选项</p>
                            <p>网络</p>
                            <p>网络接口（有线）</p>
                            <p>• 2 个 10GbE (SFP+)、2 个 10/100MB/1GbE，2 个 1GbE 管理</p>
                            <p>• 2 个 10GbE (SFP+)、4 个交换机 10/100MB/1GbE，2 个 1GbE 管理</p>
                            <p>• 2 个 10GbE、2 个 10/100MB/1GbE，2 个 1GbE 管理</p>
                            <p>• 1个PCIe网卡扩展</p>
                            <p>网络接口（无线）</p>
                            <p>• 4个适用于 LTE 和 Wi-Fi 的 SMA 连接器</p>
                            <p>• Wi-Fi 64/128位加密WEP、WPA、WPA2和802.11 a/b/g/n/ac</p>
                            <p>• 3G/4G蜂窝LTE 3GPP版本12 450Mbps DL/50Mbps UL</p>
                            <p>• 兼容5G （就绪）</p>
                            <p>安装选项</p>
                            <p>安装选项</p>
                            <p>• 适用于桌面、VESA、DIN、墙壁或天花板的单节点安装选项</p>
                            <p>• 多节点堆叠式书夹选项</p>
                            <p>• E1机柜，可并排容纳两个节点和4个电源。深度：735毫米，高度：1U</p>
                            <p>• E2浅深机柜，可并排容纳两个节点和4个电源：深度：440毫米，高度：2U</p>
                            <p>• 锁定边框和滤尘器选项</p>
                            <p>服务</p>
                            <p>有限保修</p>
                            <p>3年7*24*4全国上门有限保修服务及安装服务</p>
                            <p>安全性与可用性特征</p>
                            <p>安全性与可用性特征</p>
                            <p>• 联想值得信赖的供应商计划，安全启动和智能 USB 保护，联想Wi-Fi安全性，联想LTE安全性</p>
                            <p>• 可选的Kensington带钥匙锁兼容机箱</p>
                            <p>• Nationz 适用于中国客户的TPM 2.0</p>
                            <p>• 线缆锁定边框</p>
                            <p>环境</p>
                            <p>环境</p>
                            <p>0-55°C 的扩展操作温度，30G震动，3G振动，IEC60068，NEBS 3级认证，可选的滤尘器</p>
                            <p></p>
                            <p></p>
                            <p>超融合机构</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt28079.png' />
                            </p>
                            <p>降低IT复杂性、成本并提高可靠性</p>
                            <p>•专为横向扩展群集设计，旨在简化部署并提高易管理性，联想ThinkAgile HX系列在联想高度可靠的可扩展服务器上集成了Nutanix软件。这些设备经过全面集成、测试和配置，因此，您可以大大加快上市速度，减少基础架构维护时间和成本。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt28217.png' />
                            </p>
                            <p></p>
                            <p>减少IT孤岛</p>
                            <p>•当前的IT基础架构形成了多个孤岛，它们复杂、成本高昂且越来越难以管理。多个代次和版本的服务器、操作系统、虚拟机管理程序和存储设备需要IT部门花费全部精力进行维护，而无法帮助拓展业务。联想推出的全新超融合产品可消除这些孤岛，显著降低各种规模的数据中心的服务器、存储和虚拟化复杂性。</p>
                            <p></p>
                            <p>可靠、灵活、可扩展</p>
                            <p>•联想ThinkAgile HX系列在联想高度可靠、可扩展的服务器上集成了超融合市场领先企业Nutanix开发的软件。经过全面集成和测试的计算、存储和预加载虚拟化管理软件组成了灵活的构建模块，便于横向扩展集群轻松满足业务增长需求，同时减少数据中心占地面积。</p>
                            <p></p>
                            <p>•部署到集群中后，联想ThinkAgile HX系列会将计算和存储资源整合到单一虚拟架构中，通过单一界面提高资源利用率并简化管理。因此，您可以显著缩短IT管理时间，集中精力开展核心业务。</p>
                            <p></p>
                            <p>主要优势</p>
                            <p>•简化IT基础架构 - 允许基础架构在后台运行，而不会占用大量时间和资源。这样，您就可以集中精力开展核心业务。</p>
                            <p></p>
                            <p>•提高可靠性 - 您的业务依赖于服务器的无故障运行时间。您的利润也是如此。ITIC 2015-2016年全球服务器硬件和操作系统可靠性调查表明，联想服务器连续三年在全球所有x86服务器中可靠性名列前茅。</p>
                            <p></p>
                            <p>•服务与支持 - 联想将为整个解决方案提供专业服务，为联想ThinkAgile HX系列提供技术支持（包括咨询、实施和培训），帮助客户设计并部署满足其需求的理想解决方案。</p>
                            <p></p>
                            <p>有关联想ThinkAgile HX系列的详细信息，请联系您的联想代表或业务合作伙伴。</p>
                            <p>有关以前推出的机型（基于英特尔至强E5 v3处理器）的信息，请访问本页面。</p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 2 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="yxmt" name="yxmt" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                IBM
                            </p>
                            <p className="lstzz_ch">
                                IBM系列
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>IBM FlashSystem 5000</p>
                            <p>IBM FlashSystem® 5015 和 IBM FlashSystem 5035 是全闪存和混合闪存解决方案，旨在提供企业级功能而不影响性能，同时降低成本。内置了 IBM Spectrum Virtualize™ 的丰富功能以及 Storage Insights™ 基于 AI 的预测性存储管理和前瞻性支持。IBM FlashSystem 5000 使得各种规模的企业都能够使用现代技术。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt29164.png' />
                            </p>
                            <p>IBM FlashSystem 5000 优点</p>
                            <p>经济实惠，无需折衷</p>
                            <p>企业级功能，包含基于云的管理和支持。使用最新一代存储介质和数据降维技术，在更少的空间内存储更多的数据。</p>
                            <p></p>
                            <p>提高投资回报率</p>
                            <p>通过借助 IBM Spectrum Virtualize 的强大功能，在所有存储系统中进一步扩大丰富的数据服务集，从而彻底改造 IT 基础架构，同时提高投资回报率。</p>
                            <p></p>
                            <p>提高存储效率</p>
                            <p>基于 AI 的 IBM Storage Insights 使用预测性分析优化存储使用情况，并提供各项先发制人的功能来简化支持。</p>
                            <p></p>
                            <p>支持混合云</p>
                            <p>支持企业跨公共、私有和传统环境开展运营。灵活地扩展混合云，从小型混合云开始，随着业务需求或客户采用率的增长来逐步扩展混合云。</p>
                            <p></p>
                            <p>保护最有价值的数据资产</p>
                            <p>受益于与现代数据保护工具的集成、网络安全永续功能（例如加密和云气隙快照），并通过 IBM FlashWatch 确保完全安心无虞。</p>
                            <p></p>
                            <p>可用性高达 6 个 9</p>
                            <p>利用 IBM Spectrum Virtualize，您可以获得最高级别的可用性，从而支持关键业务型应用。</p>
                            <p></p>
                            <p>IBM FlashSystem 5000 的主要功能</p>
                            <p>单平台</p>
                            <p>创新型 FlashSystem 产品家族基于公共存储软件平台 IBM Spectrum Virtualize，它提供了强大的全闪存和混合闪存解决方案，同时还提供了功能丰富、经济有效的企业级存储解决方案。其行业领先的功能包括范围广泛的数据服务，这些服务可扩展至超过 500 个异构存储系统：自动数据移动、同步和异步复制服务（在本地复制或复制到公共云上）、高可用性配置、存储分层和数据降维技术，包括重复数据删除等。</p>
                            <p></p>
                            <p>简单易用的管理工具</p>
                            <p>通过简化直观、基于 web 的 GUI 和点击式管理功能，帮助提高 IT 生产力。IBM Spectrum Virtualize 技术（包括数据降维池选项、Easy Tier 和 IP 复制）会自动运行，几乎或完全不需要定制。</p>
                            <p></p>
                            <p>可用性高达 6 个 9</p>
                            <p>数据可用性对业务而言至关重要：任何停机时间都会带来直接影响，包括客户忠诚度和财务成本上的损失。IBM FlashSystem 5000 及最新软件可实现 99.9999% 的数据可用性。这些系统不会出现单点故障，包含企业验证的控制软件，并且能够在 I/O 时执行维护。此外，基于云的 IBM Storage Insights 还能检测配置错误，进一步提高可用性。</p>
                            <p></p>
                            <p>AI 驱动的管理和主动支持</p>
                            <p>IBM Storage Insights 可帮助您更准确地了解存储容量和性能方面的趋势，并在需要支持时加快制定出解决方案。Storage Insights 可在单一平台上监控 IBM 块存储和外部存储的运行状况及其容量和性能。它还可以运用基于 AI 的预测性分析技术，及早识别出潜在问题，将问题扼杀在摇篮之中。在需要支持时，可通过简化问题单并自动上传日志，加快问题解决。</p>
                            <p></p>
                            <p>可跨混合云部署的功能</p>
                            <p>在 IBM Cloud 和 AWS 上，IBM Spectrum Virtualize for Public Cloud 与 IBM FlashSystem 5000 协同工作，在本地存储和公共云之间交付一致的数据管理。支持您在本地和公共云之间移动数据和应用；实施新的 DevOps 战略；使用公共云进行灾难恢复，因此无需第二个数据中心；还可通过“隔离网闸”云快照提高网络的业务连续性。</p>
                            <p></p>
                            <p>将企业服务引入容器</p>
                            <p>IBM FlashSystem 5000 提供容器所需的性能、数据效率、保护和敏捷性。IBM FlashSystem 支持 Red Hat OpenShift 和 Kubernetes 容器环境，通过 IBM Block Storage CSI Driver 加快持久卷的部署，并且经过 Red Hat 和 IBM 认证。它是一种高性能解决方案，能够以敏捷的方式运行要求最苛刻的应用、服务和微服务，可构成混合云策略的基础。</p>
                            <p></p>
                            <p>IBM FlashWatch 让您高枕无忧</p>
                            <p>除了性能、容量和可伸缩性优势外，每个 IBM FlashSystem 5000 部署还包括 IBM 所谓的 FlashWatch 计划。这套全面的闪存存储计划旨在改善端到端拥有体验，让您自信满满地购买、拥有和升级 IBM Storage。</p>
                            <p></p>
                            <p>全球业务合作伙伴网络</p>
                            <p>携手 IBM 业务合作伙伴，您可获得相关技术、服务和实践，从而成功完成实施。这些合作伙伴可提供可扩展的解决方案，随着数据的增长仍可维持性能、效率和可管理性。请访问我们的 PartnerWorld 站点，搜索我们的数据库，查找您周边的业务合作伙伴。</p>
                            <p></p>
                            <p></p>
                            <p>IBM FlashSystem 9200</p>
                            <p>IBM FlashSystem 9200 对贵公司有何帮助</p>
                            <p>IBM® FlashSystem® 9200 将闪存和端到端 Non-Volatile Memory Express (NVMe) 的卓越性能、IBM FlashCore® 技术的可靠性和创新性、Storage Class Memory (SCM) 的超低延迟、IBM Spectrum Virtualize™ 的丰富特性以及 Storage Insights™ 的 AI 预测性存储管理和主动支持，有机结合到一个功能强大、快如闪电的 2U 企业级全闪存存储阵列中。FlashSystem 9200 可提供数据驱动的密集型多云存储容量，支持您轻松地根据关键需求添加最合适的多云解决方案。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt31450.png' />
                            </p>
                            <p>优势</p>
                            <p>加快业务执行速度</p>
                            <p>使用端到端 NVMe 支持的 IBM FlashCore 技术和 SCM 支持，释放数据价值，加快业务执行速度，从而降低延迟。</p>
                            <p></p>
                            <p>提高投资回报率</p>
                            <p>通过借助 IBM Spectrum Virtualize 的强大功能，在所有存储系统中进一步扩大丰富的数据服务集，从而彻底改造 IT 基础架构，同时提高投资回报率。</p>
                            <p></p>
                            <p>提高存储效率</p>
                            <p>基于 AI 的 IBM Storage Insights 使用预测性分析优化存储使用情况，并提供各项先发制人的功能来简化支持。</p>
                            <p></p>
                            <p>已启用混合多云</p>
                            <p>支持公共、私有和传统环境中的企业。</p>
                            <p>灵活扩展您的混合多云，可从小规模开始，然后随着业务需求或客户采用量的增长而扩展。</p>
                            <p></p>
                            <p>放心部署</p>
                            <p>在 IBM FlashWatch 与 IBM Storage Utility 程序的支持下，提升成本效益，自信地部署领先的存储解决方案。</p>
                            <p></p>
                            <p>可用性高达 6 个 9</p>
                            <p>获得最高水平的可用性，支持任务关键型应用。</p>
                            <p></p>
                            <p>IBM FlashSystem 9200 主要功能</p>
                            <p>基于 IBM Spectrum Virtualize 构建</p>
                            <p>利用端到端 NVMe 和 iSER 实现业务现代化</p>
                            <p>从传统技术迁移到新技术</p>
                            <p>AI 驱动的管理和主动支持</p>
                            <p>可跨混合多云部署的功能</p>
                            <p>将企业服务引入容器</p>
                            <p>数据降维选项</p>
                            <p>IBM Easy Tier®</p>
                            <p>IBM FlashWatch 让您高枕无忧</p>
                            <p>可用性高达 6 个 9</p>
                            <p>扩展系统功能</p>
                            <p></p>
                            <p>SAN Volume Controller (SVC)</p>
                            <p>SAN Volume Controller (SVC) 对贵公司有何用途</p>
                            <p>IBM SAN Volume Controller (SVC) 是一款企业级存储系统，采用 IBM Spectrum Storage™ 产品家族成员 IBM Spectrum Virtualize™ 软件构建而成，通过支持对于成功至关重要的大规模全新工作负载，可帮助企业实现更为出色的数据经济效益。SVC 系统可以处理移动应用和社交应用产生的海量数据，支持灵活的混合云部署，并提供从最新分析技术中获取洞察所需的性能和可扩展性。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt32345.png' />
                            </p>
                            <p>优势</p>
                            <p>提高数据价值</p>
                            <p>通过提高利用率并使用数据压缩技术，降低数据存储成本，并加速应用以快速获得业务洞察。</p>
                            <p>提高数据安全性</p>
                            <p>有助于保护数据免遭泄露，并支持完整的高可用性策略，包括数据和应用迁移以及全面的灾难恢复保护（包括云端迁移）。</p>
                            <p>增强数据简单性</p>
                            <p>通过紧密集成的企业级功能和对异构存储的一致性管理，支持独立于基础架构选项的数据策略。</p>
                            <p>可用性高达 6 个 9</p>
                            <p>获得最高水平的可用性，支持任务关键型应用。</p>
                            <p></p>
                            <p>IBM SAN Volume Controller 的主要功能</p>
                            <p>增强存储功能</p>
                            <p>混合多云优势</p>
                            <p>数据降维</p>
                            <p>非凡的存储效率</p>
                            <p>提高应用可用性</p>
                            <p>分层存储</p>
                            <p>可用性高达 6 个 9</p>
                            <p>多站点高可用性</p>
                            <p>获取存储可视性、洞察和可控性</p>
                            <p>可扩展性和性能</p>
                            <p>简化管理</p>
                            <p>为服务器虚拟化提供补充支持</p>
                            <p></p>
                            <p>IBM TS4300 磁带库</p>
                            <p>TS4300 磁带库对贵企业有何用途</p>
                            <p>IBM® TS4300 磁带库是一款高密度、高度可扩展、易于管理的解决方案，旨在长期安全地存储数据，同时帮助降低数据中心空间和公用事业相关的成本。其模块化设计支持您根据需要增加盒带和磁带机容量，可垂直向上扩展为 7 个模块，以安装 Linear Tape-Open (LTO) Ultrium 盒带、磁带机和冗余电源。IBM TS4300 增强的现代数据保护可帮助满足安全性和合规性需求。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt32912.png' />
                            </p>
                            <p>优势</p>
                            <p>按需扩展磁带存储容量</p>
                            <p>模块化设计支持您根据需要来增加盒带和磁带机容量。TS4300 可垂直向上扩展为 7 个模块，以安装 LTO Ultrium 8、7 和 6 盒带。</p>
                            <p>满足合规需求</p>
                            <p>TS4300 支持 WORM 盒带以增强现代数据保护，并通过 IBM Security Key Lifecycle Manager 提供库管理的加密。</p>
                            <p>简化部署和管理</p>
                            <p>TS4300 基于嵌入式开源软件架构，有助于加快部署并简化管理。</p>
                            <p>降低数据中心空间的资本支出和运营支出</p>
                            <p>TS4300 是一款高密度、高度可扩展、易于管理的磁带库，旨在长期安全地存储数据，同时帮助降低数据中心空间和公用事业相关的成本。</p>
                            <p>形成基于气隙的网络安全永续基础架构</p>
                            <p>TS4300 通过创建黑客基本上不可能接触到的数据拷贝，提供简单、内置的气隙式数据保护，消除了勒索软件感染和再感染周期。</p>
                            <p></p>
                            <p>主要功能</p>
                            <p>模块化设计，轻松实现扩展</p>
                            <p>安全与合规能力</p>
                            <p>基础库即可实现完整的系统管理</p>
                            <p>对存储数据提供直观的图形化访问</p>
                            <p>快速部署，简化管理</p>
                            <p>可与最新的 IBM LTO Ultrium 技术兼容</p>
                            <p></p>
                            <p></p>
                            <p>IBM TS2280 磁带机</p>
                            <p>IBM TS2280 磁带机对贵公司有何用途</p>
                            <p>IBM® TS2280 磁带机整合了最新一代的 Linear Tape-Open (LTO) 技术，提供了比上一代更高的容量。 除备份外，TS2280 还旨在帮助您的企业处理现代磁带用例中不断增长的数据需求，例如，云、物联网 (IoT) 和活动文件归档。 通过每个盒带提供高达 30 TB 的物理存储容量（压缩率为 2.5:1），TS2280 成为面向需要数据备份和低成本归档数据存储的中小型企业的卓越磁带存储解决方案。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt33635.png' />
                            </p>
                            <p>优点</p>
                            <p>降低存储成本</p>
                            <p>在相同占用空间内获取 2 倍的存储容量。 每个盒带存储多达 30 TB（压缩）数据，是上一代 LTO 盒带容量的两倍。</p>
                            <p></p>
                            <p>简化数据管理</p>
                            <p>使基于磁带的数据便于访问。 IBM Spectrum Archive™ 支持面向磁带的 IBM Linear Tape File System™ (IBM LTFS)。</p>
                            <p></p>
                            <p>帮助满足合规性需求</p>
                            <p>利用数据加密和单写多读 (WORM) 支持来增强安全性与合规性。</p>
                            <p></p>
                            <p>提高灵活性</p>
                            <p>紧凑的外部机柜具有理想的便携性，有助于实现灵活安装（可进行桌面安装，或在 19 英寸机架上并排安装两个 TS2280）。</p>
                            <p></p>
                            <p>为用户提供强大的支持</p>
                            <p>通过与 IBM Spectrum Protect™ 集成，用户能够创建、管理和归档各种内容（包括内容的并发拷贝）以及活动、不活动和非现场内容。</p>
                            <p></p>
                            <p>轻松集成</p>
                            <p>跨平台开放式系统支持可帮助将大容量磁带备份与运行 Microsoft Windows 或 UNIX 操作系统的各种系统有效集成。</p>
                            <p></p>
                            <p>主要特性</p>
                            <p>快速数据传输</p>
                            <p>TS2280 磁带机可将未压缩数据传输率提升至 300 MBps（压缩率为 2.5:1 时，最高可达 750 MBps），并拥有 6 Gbps SAS 接口连接。</p>
                            <p></p>
                            <p>简化的访问与管理</p>
                            <p>IBM Spectrum Archive™ 通过支持基于文本的文件标记来帮助简化 TS2280 访问与管理，可以更直观地搜索磁带机内容。 IBM Spectrum Archive 支持面向磁带的 LTFS，并提供磁带机内容的 GUI。 这些功能有助于使基于磁带的数据与基于磁盘的数据或基于闪存的数据一样易于查找和访问。 通过使磁带变得简单且直观，Spectrum Archive LTFS 为 LTO Ultrium 磁带的新用途和应用敞开了大门。</p>
                            <p></p>
                            <p>灵活的网络集成</p>
                            <p>TS2280 6 Gbps SAS 接口使其能够连接到各种开放式系统服务器。 为了实现灵活的网络集成，这个紧凑的外部磁带机提供两个 SAS 端口和一个以太网端口，可并排安装在 19 英寸机架上，也可用于独立配置。</p>
                            <p></p>
                            <p>最大程度提升性能和增强安全性</p>
                            <p>IBM Spectrum Archive 创造了高效的“数据占用空间”，以成本动态存储每一比特的数据，从而帮助最大限度地提高性能并确保安全。 IBM Spectrum Archive 支持长期存储数字资产，因此可在未来引用资产和实现资产的经济效益。</p>
                            <p></p>
                            <p>针对磁带数据的文件级访问</p>
                            <p>LTO Ultrium 8 可提供分区支持，通过结合 IBM Spectrum Archive，支持用户在文件级别访问磁带数据。 此支持有助于用户快速查找和更新信息。</p>
                            <p></p>
                            <p>可交换的 Ultrium 8 和 Ultrium 7 磁带介质</p>
                            <p>LTFS 磁带机设计为可在兼容的 LTO 磁带机与受支持的操作系统之间进行交换，因此管理员无需担忧硬件和软件兼容性。 LTFS 管理员可以在 Ultrium 8 磁带上快速访问、存储或归档文件，并以非常便于使用的格式将文件传输至其他 TS2280，或者从其他位置传输文件。</p>
                            <p></p>
                            <p>数据加密</p>
                            <p>LTO Ultrium 8 技术还设计为本机支持数据加密，LTO Ultrium 8 磁带机本身自带核心硬件加密和解密功能。</p>
                            <p></p>
                            <p>大容量和操作简便性</p>
                            <p>TS2280 磁带机支持针对 LTO Ultrium 8 的 LTO Consortium 压缩规范，每个盒带提供高达 30 TB 的数据存储空间（本机 12 TB），是前一代 LTO Ultrium 7 盒带压缩容量的两倍。 除读取和写入 LTO Ultrium 8 盒式磁带外，TS2280 还可以读取和写入 LTO Ultrium 7 盒带。 TS2280 支持 LTO 磁带盒的自描述功能，实现操作简易性。</p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 3 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="rjyy" name="rjyy" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                DELL
                            </p>
                            <p className="lstzz_ch">
                                DELL系列
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>Dell EMC VxRail设备</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt35239.png' />
                            </p>
                            <p>适用于VMware的超融合设备</p>
                            <p>独有的HCI设备针对VMware环境进行了优化。</p>
                            <p></p>
                            <p>产品特征</p>
                            <p>可立即投入使用的超融合VMware虚拟化设备</p>
                            <p>Dell EMC VxRail Appliance系列可简化虚拟化应用程序的部署。与传统基础架构相比，VxRail的部署速度快73 %*，支持利用Dell EMC PowerEdge平台和VMware vSAN的连续创新，以通过可预测的方式扩展VMware环境，同时在五年内实现惊人的619 % ROI*。</p>
                            <p></p>
                            <p>通过集成到VMware生态系统，轻松打造IT。</p>
                            <p>利用简化的部署和生命周期管理，以可预测的方式发展。</p>
                            <p>利用可帮助您专注于目标的技术，持续创新。</p>
                            <p>VxRail Appliance可提供数以百万计的配置选项，带来可高度预测的性能，从而满足任何HCI使用情形。</p>
                            <p></p>
                            <p>入门级节点</p>
                            <p>VxRail E系列是半高节点，适合远程办公室或空间有限的环境。该系列中每台应用方案的每个1U机箱有一个节点，支持群集在极小的占用空间中优化存储和计算资源，同时提供一次扩展一个机架单元节点的选项。型号包括VxRail E560（混合），以及VxRail E560F（全闪存）。这些都是基于PowerEdge R640机架式服务器的单或双处理器型号。</p>
                            <p></p>
                            <p>高性能节点</p>
                            <p>VxRail P系列是高性能节点，针对数据库等繁重工作负载进行了优化。该系列中每个设备的每2U机箱有一个节点。型号包括VxRail P570（混合），以及VxRail P570F（全闪存）。这些都是基于PowerEdge R740机架式服务器的单或双处理器型号。</p>
                            <p></p>
                            <p>VDI优化节点</p>
                            <p>VxRail V系列是显卡就绪型节点，可支持多达3个图形加速器，非常适合高端2D/3D可视化等专业使用情形。每台设备中的每2U机箱有一个节点。型号包括VxRail V570（混合），以及VxRail V570F（全闪存）。这些都是基于PowerEdge R740机架式服务器的双处理器型号。</p>
                            <p>高密度存储节点</p>
                            <p>VxRail S系列是容量优化型节点，可针对协作、数据和分析等使用情形提供扩展容量。每台设备中的每2U机箱有一个节点。该系列包括VxRail S570（仅混合），基于PowerEdge R740xd机架式服务器，可提供单或双处理器配置。</p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 4 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="rh3c" name="rh3c" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                H3C
                            </p>
                            <p className="lstzz_ch">
                                H3C系列
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>H3C UniServer R4950 G5服务器</p>
                            <p>H3C UniServer R4950 G5 系列机架式服务器是新华三集团自主研发的、基于 AMD EPYC处理器的一款2U2路的机架式服务器。可广泛应用于互联网、云计算、IDC(Internet Data Center，互联网数据中心)、企业市场、运营商等场景。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt36364.png' />
                            </p>
                            <p>虚拟化平台</p>
                            <p>• 最大支持128个内核和4TB内存</p>
                            <p>• 为适应虚化平台量身打造</p>
                            <p>性能出色计算灵活</p>
                            <p>• AMD EPYC 处理器</p>
                            <p>• 最高10个 PCIe 4.0/3.0标准插槽</p>
                            <p>• 2个板载 OCP 3.0插槽</p>
                            <p>整体解决方案</p>
                            <p>• 提供虚化融合管理</p>
                            <p>• 简化部署优化运维</p>
                            <p></p>
                            <p></p>
                            <p>产品特性</p>
                            <p>面向现代数据中心的虚拟化工作负载</p>
                            <p>更高的性能和安全特性</p>
                            <p>H3C UniServer R4950 G5 系列机架式服务器是新华三集团自主研发的、基于 AMD EPYC处理器的机架式服务器，2U 高度，支持双路 CPU。可广泛应用于互联网、云计算、IDC(Internet Data Center，互联网数据中心)、企业市场、运营商等场景。R4950 G5具有低功耗、高可靠、灵活扩展性强、易管理，易部署等优点。</p>
                            <p>借助H3C UniServer R4950 G5服务器，您可以适应匹配各种企业工作负载：</p>
                            <p>- 支持双路AMD EPYCTM CPU，支持多种安全相关功能，包括AMD安全处理器、安全内存加密(SME) 及安全加密虚拟化 (SEV)。</p>
                            <p>- 支持多达32个DDR4 ECC内存插槽，最高3200MT/s，最大4TB可扩展容量</p>
                            <p>- 支持高达10个PCIe 4.0 扩展插槽和2个OCP 3.0槽位，有效提高系统扩展能力</p>
                            <p>- 智能阵列RAID控制器，为数据读写提供高速缓存，支持掉电数据掉保护功能。</p>
                            <p>- 支持多达2个双宽或6个单宽图形处理单元 (GPU)，可实现工作负载加速。</p>
                            <p>- 高效的通用插槽电源，可实现96%的能效</p>
                            <p>- 支持可信平台模块（ TCM1.0/TPM2.0），可提供高级加密功能</p>
                            <p>- 系统管理模块HDM配合FIST管理软件可监控系统运行状态，提供远程管理功能</p>
                            <p>为了支持您的异构IT环境，R4950 G5服务器可支持 Microsoft® Windows® 和 Linux操作系统，以及 VMware 和 H3C CAS 环境。</p>
                            <p>产品规格</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大支持2颗AMD EPYC Rome/Milan处理器 ，每颗处理器最大支持64个核心
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大支持32根DDR4内存插槽，速率最高支持3200MT/s，支持RDIMM或LRDIMM，最大容量4.0TB
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        存储控制器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        RAID控制器，支持 RAID 0/1/5/6/10/50/60
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        FBWC
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最高支持4GB
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        存储
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持12LFF(3.5inch)+ Rear 2SFF&4LFF
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持25SFF(2.5inch)+Rear 2LFF&4SFF
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        24个NVMe(2.5inch)（21H1）*
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        板载12个SATA接口
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持2个2280/22110 M.2扩展
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        网络
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持2张OCP3.0网卡*；
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可扩展1G/10G/25G等网卡；支持InfiniBand卡
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        扩展插槽
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最多支持10个PCIe 4.0/3.0标准插槽和2个板载OCP3.0插槽*
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        接口
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        前部：1个VGA，左挂耳 USB2.0 ，右挂耳USB3.0，1个Type C接口
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内置：2个USB3.0
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        后部：1个VGA，1个串口，2个USB3.0，1个HDM专用网口
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        GPU支持
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持2块双宽GPU卡*或6块单宽（X16）GPU卡
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        光驱
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持外置光驱
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        管理
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        HDM无代理管理工具 (带独立管理端口) 和H3C FIST管理软件；支持LED故障诊断面板
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        安全性
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持机箱入侵检测，TCM/TPM安全模块
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        电源和散热
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选白金级别550W/800W/850W/1200W/冗余电源
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持热插拔冗余风扇
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        认证
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持CCC，CECP，SEPA等认证
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        工作温度
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        5℃～45℃ （工作温度支持受不同配置影响，详情请参考详细产品技术文档描述）
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        外形/机箱尺寸
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        2U机箱：
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        机箱尺寸（不含安全面板）（长x宽x高）：748.0mm×445.4mmx87.5mm
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        机箱尺寸（包含安全面板）（长x宽x高）：775.5mm×445.4mmx87.5mm
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        保修
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        三年5*9，下一工作日响应(NBD)
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>HPE ProLiant DL360 Gen10服务器</p>
                            <p>HPE ProLiant DL360 Gen10 服务器的安全性、敏捷性及出色的性能，适用于要在空间有限的环境中部署多种工作负载的数据中心。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt38124.png' />
                            </p>
                            <p>产品特性</p>
                            <p>出色的性能，多用途计算能力</p>
                            <p></p>
                            <p>HPE ProLiant DL360 Gen10 服务器支持采用行业标准技术的英特尔®至强®可扩展处理器（多达 28 核、12G SAS 和 3.0 TB 2933 MT/秒的HPE DDR4 SmartMemory）。</p>
                            <p></p>
                            <p>HPE持久性内存搭配DRAM，可打造出快速、高容量、低成本的内存和存储装置，实现数据的快速存储、移动和处理，进而助力进行大数据工作负载和分析转型。</p>
                            <p></p>
                            <p>通过配有多达10个SFF和4个LFF驱动器的灵活驱动器配置以及最多支持10个NVMe PCIe固态硬盘的选件，实现更高容量，出色的性能、容量和可靠性，并以合理的成本满足各种客户群和工作负载要求。</p>
                            <p></p>
                            <p>HPE ProLiant DL360 Gen10服务器每个机箱支持多达12个NVDIMM，而且容量是一代HPE NVDIMM的2倍，每个系统可提供多达192 GB的容量。</p>
                            <p></p>
                            <p>灵活多样的创新设计</p>
                            <p></p>
                            <p>10 SFF NVMe机箱背板以及 8+2 SFF和4 LFF机箱提供混合搭配SAS/SATA 和 NVMe的能力，可支持全新uFF 和 M.2存储选件。</p>
                            <p></p>
                            <p>提供HPE FlexibelLOM或PCIe标准网卡可选，支持1GbE、10GbE、25Gbe、100Gbe……以适应不断变换的业务需求。</p>
                            <p></p>
                            <p>支持多达3个标准PCIe插槽，提供出色的扩展性。</p>
                            <p></p>
                            <p>安全创新</p>
                            <p></p>
                            <p>只有HPE提供将主要固件直接固定在硅芯片上的工业标准服务器。借助可信硅根，内置安全保护贯穿服务器整个生命周期。</p>
                            <p></p>
                            <p>在实时的固件有效性验证机制下，几百万行固件代码在服务器操作系统启动前运行，HPE iLO高级安全版提供每24小时核心系统固件对服务器固件进行有效性和可信度验证。</p>
                            <p></p>
                            <p>安全恢复功能在检测到不良代码时允许服务器固件回退到最后一次正常状态或出厂设置。</p>
                            <p></p>
                            <p>增强安全功能包括服务器配置锁、iLO 安全仪表板和Workload Performance Advisor，可实时提供调优建议以改善性能。</p>
                            <p></p>
                            <p>TPM提供额外的安全选项，阻止未经授权的服务器访问，而通过安全存储部件的授权，当服务器机盖移开后，入侵侦测套件留下记录和告警。</p>
                            <p></p>
                            <p>世界级性能</p>
                            <p></p>
                            <p>通过智能系统优化技术创新，HPE服务器可利用自定义配置文件来调整内部资源，优化工作负载性能，对所有负载，包括时延敏感负载提供增强的性能表现，如延迟减缓高频交易。</p>
                            <p></p>
                            <p>HPE ProLiant DL360Gen10服务器支持行业标准技术，英特尔最新至强®第二代可扩展处理器系列，提供多达28个内核，12G SAS，3.0TB HPE DDR4 2933MT/s智能内存。</p>
                            <p></p>
                            <p>产品规格</p>
                            <p>HPE ProLiant DL360 Gen10 服务器</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大支持2颗英特尔®至强®第二代可扩展系列处理器，4/8/12/16/18/20/22/24/26/28个内核，最大支持功率205W
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大支持24根DDR4内存插槽，速率最高支持2933MT/s，支持RDIMM或LRDIMM，最大容量3.0TB
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        永久性内存
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持12根HPE DC永久内存，最大容量6.0TB
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        存储控制器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        标配板载阵列控制器，支持SATA RAID 0/1/10/5
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选基于阵列卡专用插槽的HBA卡，支持SATA/SAS RAID 0/1/10/5
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选基于阵列卡专有插槽的Smart Array系列阵列卡，支持SATA/SAS RAID0/1/10/5/6/50/60/1 可选基于标准PCIe插槽的HBA卡和Smart Array阵列卡
                                                        最高支持4GB FBWC
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最高支持4GB FBWC
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        存储
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        4 个 LFF SAS/SATA、8 个 SFF SAS/SATA 和 2 个 NVMe、10 个 SFF SAS/SATA、10个 SFF NVMe、1 个 SFF 或 1 个可选双 UFF 后置驱动器，具体取决于机型
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        网络
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        板载4口千兆网络适配器/网卡可选NC型号不含板载网络控制器
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选FlexibleLOM网络适配器
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选基于标准PCIe插槽的网络适配器
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        扩展插槽
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大3个PCIe标准插槽
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        接口
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选前置VGA和串口，标配后置VGA；5 个USB 3.0（1前置，2后置，2内置）；可选2个MicroSD
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        GPU支持
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持2块单宽GPU
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        光驱
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持外置光驱；4LFF和8SFF机型支持内置光驱
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        管理
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        标配iLO标准版，可选iLO高级版和OneView高级版
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        电源和散热
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选500W/800W/1600W 1 + 1热拔插冗余电源，最高96%钛金, 热插拔冗余风扇
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        工作温度
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        5℃～45℃
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        机箱尺寸
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        43.46 x 70.7 x 4.29 cm （宽 x 深 x 高，SFF机型）
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        44.46 x 74.98 x 4.29 cm （宽 x 深 x 高，LFF机型）
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        保修
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        渠道销售预配置（BTO）机型3年5×9下一工作日响应
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        项目销售按单配置（CTO）机型3年7×24×4小时服务，包含基础上门安装服务
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>HPE ProLiant DL380 Gen10服务器</p>
                            <p>HPE ProLiant DL380 Gen10服务器</p>
                            <p>HPE ProLiant DL380 Gen10提供性能、安全性和扩展性，成为任何业务负载环境所需的最值得信赖的计算平台。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt40329.png' />
                            </p>
                            <p>产品特性</p>
                            <p>灵活设计，易于扩展：您可随着业务增长逐步增加投资</p>
                            <p></p>
                            <p>HPE ProLiant DL380 Gen10服务器拥有一个可灵活扩展的机箱，提供新的HPE模块化硬盘背板作为可配置选件，支持最多30个2.5"小盘，19个3.5"大盘，或者20个NVMe硬盘选件，同时支持最多3个双宽GPU。</p>
                            <p></p>
                            <p>HPE DC永久内存搭配 DRAM，可打造出快速、高容量、低成本的内存和存储装置， 实现数据的快速存储、移动和处理，进而助力进行大数据工作负载和分析转型。</p>
                            <p></p>
                            <p>结合集成的SATA HPE动态智能阵列控制器S100i满足启动、数据和媒体需求，经过重新设计的HPE智能阵列控制器允许用户灵活选择最适合客户环境的12Gb/s控制器，同时支持SAS接口和HBA模式。</p>
                            <p></p>
                            <p>板载的4x1GbE网络控制器/网卡可选NC型号不含板载网络控制器，您可选择 HPE FlexibleLOM或标准PCI-E的网络适配器，提供从1GbE到40GbE的网络带宽范围和构造选择，客户可适应、发展不断改变的业务需求。</p>
                            <p></p>
                            <p>除了传统的操作系统之外，还支持从Azure到Docker再到ClearOS广泛的操作环境。</p>
                            <p></p>
                            <p>安全创新</p>
                            <p></p>
                            <p>只有HPE提供将主要固件直接固定在硅芯片上的工业标准服务器。借助可信硅根，内置安全保护贯穿服务器整个生命周期。</p>
                            <p></p>
                            <p>在实时的固件有效性验证机制下，几百万行固件代码在服务器操作系统启动前运行，HPE iLO高级安全版提供每24小时核心系统固件对服务器固件进行有效性和可信度验证。</p>
                            <p></p>
                            <p>安全恢复功能在检测到不良代码时允许服务器固件回退到最后一次正常状态或出厂设置。</p>
                            <p></p>
                            <p>增强安全功能包括服务器配置锁、iLO 安全仪表板和Workload Performance Advisor，可实时提供调优建议以改善性能。</p>
                            <p></p>
                            <p>TPM提供额外的安全选项，阻止未经授权的服务器访问，而通过安全存储部件的授权，当服务器机盖移开后，入侵侦测套件留下记录和告警。</p>
                            <p></p>
                            <p>世界级性能</p>
                            <p></p>
                            <p>通过智能系统优化技术创新，HPE服务器可利用自定义配置文件来调整内部资源，优化工作负载性能，对所有负载，包括时延敏感负载提供增强的性能表现，如延迟减缓高频交易。</p>
                            <p></p>
                            <p>HPE ProLiant DL380Gen10服务器支持行业标准技术，英特尔最新至强®第二代可扩展处理器系列，提供多达28个内核，12G SAS，3.0TB HPE DDR4 2933MT/s智能内存。</p>
                            <p></p>
                            <p>HPE ProLiant DL380 Gen10支持最多3个双宽或7个单宽GPU加速工作负载。</p>
                            <p></p>
                            <p>产品规格</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大支持2颗英特尔®至强®第二代可扩展系列处理器，4/8/12/16/18/20/22/24/26/28个内核，最大支持功率205W
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大支持24根DDR4内存插槽，速率最高支持2933MT/s，支持RDIMM或LRDIMM，最大容量3.0TB
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        永久性内存
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持12根NVDIMM永久性内存，最大容量192GB/HPE DC永久内存最大支持6TB
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        存储控制器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        标配板载阵列控制器，支持SATA RAID 0/1/10/5
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选基于阵列卡专用插槽的HBA卡，支持SATA/SAS RAID 0/1/10/5
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选基于阵列卡专有插槽的Smart Array系列阵列卡，支持SATA/SAS RAID0/1/10/5/6/50/60/1 ADM 可选基于标准PCIe插槽的HBA卡和Smart Array阵列卡
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最高支持4GB FBWC
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        存储
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        24+6个 SFF/12LFF + 4+3 个 SFF+最大 2 个 SFF HDD/SSD硬盘、20 个 NVMe PCIe SSD 和+ m.2 可用扩展
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        网络
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        板载4口千兆网络适配器/网卡可选NC型号不含板载网络控制器6
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选FlexibleLOM网络适配器
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选基于标准PCIe插槽的网络适配器
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        扩展插槽
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        标配3个PCIe 3.0可用插槽，最大8个PCIe可用插槽
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        接口
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选前置VGA和串口，标配后置VGA；5个USB 3.0（1前置，2后置，2内置）；可选2个MicroSD
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        GPU支持
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        多达（7 个）单宽/（3 个）双宽
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        光驱
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        支持外置光驱；8LFF和8SFF机型支持内置光驱
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        管理
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        标配iLO标准版，可选iLO高级版和OneView高级版
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        电源和散热
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        可选500W/800W/1600W 1 + 1热拔插冗余电源，最高96%钛金, 热插拔冗余风扇
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        工作温度
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        5℃～45℃，ASHRAE A3 & A4
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        机箱尺寸
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        44.54 x 67.94 x 8.73 cm （宽 x 深 x 高，SFF机型）
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        44.54 x 73.02 x 8.73 cm （宽 x 深 x 高，LFF机型）
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        保修
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        渠道销售预配置（BTO）机型3年5×9下一工作日响应
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        项目销售按单配置（CTO）机型3年7×24×4小时服务，包含基础上门安装服务
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>HPE ProLiant DL580 Gen10服务器</p>
                            <p>HPE ProLiant DL580 Gen10服务器采用4U机箱，是一台安全高度可扩展的4P服务器，具有高性能、可扩展性和可用性。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt42443.png' />
                            </p>
                            <p>产品特性</p>
                            <p>可扩展的4U服务器的可扩展性能</p>
                            <p></p>
                            <p>完全重新设计的HPE ProLiant DL580 Gen10服务器具有可扩展的4U外形，提供4P 计算，支持多达四个Intel®Xeon®铂金级和黄金级处理器。与上一代产品相比，该处理器的性能提高达28%，内核增加达17%。</p>
                            <p></p>
                            <p>多达48个DIMM槽支持最多6TB的2933MT/s HPE DDR4 SmartMemory。HPE DDR4 SmartMemory在提高工作负载性能和电源效率的同时，通过增强的错误处理能力减少了数据丢失和停机时间。</p>
                            <p></p>
                            <p>HPE FlexibleLOM适配器有众多选择,可提供各种网络速度 (1GbE~25GbE) 和结构， 使您能够适应时刻变化和增长的业务需求。</p>
                            <p></p>
                            <p>智能系统调优 (Intelligent System Tuning) 带来的HPE创新，通过使用自定义配置文件进行内部资源优化，提升了工作负载性能，提高了所有工作负载 (包括延迟敏感型工作负载，例如通过对抖动进行平滑而实现的高频交易) 的吞吐量。</p>
                            <p></p>
                            <p>对多个工作负载具有显著的可扩展性和可用性</p>
                            <p></p>
                            <p>HPE ProLiant DL580 Gen10服务器有一个灵活的处理器托盘，使服务器可以根据需要从一个处理器扩展到四个，节省了前期成本；它还设计了一个灵活的驱动器托架， 支持多达48个小尺寸 (SFF) SAS/SATA驱动器和多达20个NVMe驱动器。</p>
                            <p></p>
                            <p>该服务器支持多达16个PCIe 3.0扩展插槽，包括多达4个全长/全高的图形处理单元(GPU) 以及可提供更高可扩展性的网络卡或存储控制器。</p>
                            <p></p>
                            <p>该服务器还支持多达4个能效为96%的HPE 800W或1600W灵活插槽电源，通过2+2 配置和灵活的电压范围，实现了更高的功率冗余。</p>
                            <p></p>
                            <p>安全可靠</p>
                            <p></p>
                            <p>HPE提供具有其自己设计的BMC (HPE iLO5) 硅片的行业标准服务器。该硅片进行了物理上的改变，以保护大部分服务器基本固件，包括HPE iLO、UEFI、IE、ME和SPLD。对HPE硅片的定位创造了真正具有保护意义的硅信任根 (可信硅根)。</p>
                            <p></p>
                            <p>在服务器操作系统启动和运行环境固件验证之前，HPE iLO Advanced Premium Security Edition使数百万行的固件代码开始运行，服务器固件每24小时检查一次， 验证基本系统固件的有效性和可信度。</p>
                            <p></p>
                            <p>安全恢复 (Secure Recovery) 允许服务器固件在检测到泄漏的代码后回滚到最后一次已知的良好状态或出厂设置。</p>
                            <p></p>
                            <p>可信平台模块 (TPM/TCM) 提供额外的安全选项，以防止未经授权访问服务器；并在移除服务器上盖时触发入侵检测包 (Intrusion Detection Kit) 登录并报警，确保存储在服务器的组件的安全。</p>
                            <p></p>
                            <p>加速IT服务交付的敏捷的基础设施管理</p>
                            <p></p>
                            <p>HPE ProLiant DL580 Gen10服务器与HPE OneView软件的结合，为跨服务器、存储和网络的自动操作简化管理提供基础设施。</p>
                            <p></p>
                            <p>服务器生命周期管理可使用一套嵌入式的、可下载的工具，包括统一可扩展固件接口 (UEFI)、智能资源调配 (Intelligent Provisioning)；用于监控和管理的HPE iLO 5； HPE iLO Amplifier Pack 、 Smart Update Manager (SUM) 和 Service Pack for ProLiant (SPP)。</p>
                            <p></p>
                            <p>产品规格</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        外形
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        外形
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        Intel®Xeon®可扩展处理器
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器数量
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        4/3/2/1个
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器可用内核
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        每个处理器28/26/24/22/20/18/16/14/12/10/8/6/4个内核，具体取决于型号
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器缓存
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        每个处理器13.75MB L3/16.50MB L3/19.25MB L3/22.00MB L3/24.75MB L3/27.50MB L3/30.25MB L3/33.00MB L3/35.75MB L3/38.50MB L3，具体取决于型号
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        处理器速度
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最高3.6GHz，具体取决于处理器
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        电源类型
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最多4个HPE灵活插槽电源，具体取决于型号。支持240VDC高压直流
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        扩展插槽
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最多16个全高PCIe插槽，支持4个双宽GPU，请参阅配置指南了解详细信息
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大内存
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最大6.0TB，具体取决于处理器
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存插槽
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最多48个DIMM 插槽
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        内存类型
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        HPE DDR4 SmartMemory，可选24个NVDIMM永久内存，支持24个DCPMM持久内存
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        硬盘驱动器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最多支持48个SFF硬盘，支持SATA/SAS/SSD，包括支持20个NVMe SSD硬盘
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        系统风扇特征
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        12 (11+1) 热插拔冗余标准
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        网络控制器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        选配的FlexibleLOM，支持1Gb/10Gb/25Gb，可选PCIe网卡可支持最高100Gb网络
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        存储控制器
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        HPE Smart Array S100i或HPE智能阵列控制器，具体取决于型号，支持最高4GB缓存
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        最小尺寸 (宽×深×高)
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        17.48×44.55×75.18cm
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        重量
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        51.71kg
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        基础设施管理
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        包括具有Intelligent Provisioning (内嵌) 的HPE iLO标准版以及HPE OneView标准版 (需要下载)
                                                    </span>
                                                </p>
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        选配：HPE iLO Advanced、HPE iLO Advanced Premium Security Edition和HPE OneView Advanced (可选择许可证)
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="106" valign="top" className="table4_header1">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        标准保修
                                                    </span>
                                                </p>
                                            </td>
                                            <td width="340" valign="top" className="table4_header2">
                                                <p>
                                                    <span className="table4_header1_header01_header001">
                                                        渠道销售预配置（BTO）机型3年5×9下一工作日响应，项目销售按单配置（CTO）机型3年7x24x4服务，包含基础上门安装服务
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 5 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="rh3c" name="rh3c" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                SUNDRAY
                            </p>
                            <p className="lstzz_ch">
                                信锐
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>无线控制器</p>
                            <p>信锐 NAC-6100 下一代网络控制器是信锐技术自主研发的多元化、高性能的网络控制器设备，集信锐下 一代网络控制器、认证服务器、内网终端状态可视化分析、大数据分析、上网行为管理、上网行为审计、 流量控制、VPN、防火墙、有线无线管理系统。NAC-6100 控制器可管理信锐全系列 AP 和安视系列交换机， 并具有二三层漫游、智能射频、多元化的认证方式、数据分析、精细化的用户行为管理、灵活 的 QoS 控制、无线协议优化、有线无线一体化等功能。对内网有线疑似中毒终端的筛查、定位以及封堵等 配合信锐无线 AP 和安视系列交换机系列，定位于中型 WLAN 接入业务，如：企业、商超连锁、校园、 酒店、医院、政府、金融、景区等高速的 WIFI 应用场景。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100003.png' />
                            </p>
                            <p>产品特性</p>
                            <p>前所未有的无线体验 </p>
                            <p>信锐下一代网络控制器支持二三层漫游，可自动调节无线接入点的工作信号及发射频率， 支持接入点间负载均衡，有效ᨀ高带宽的的利用率。采用时间公平算法，让不同协商速率的终端占用相等 的无线信道时间，ᨀ升用户的上网体验。信锐下一代网络控制器可基于应用、用户、时间等因素分配不同 的流控策略，灵活分配带宽资源，最大化ᨀ高带宽价值。</p>
                            <p></p>
                            <p>用户认证</p>
                            <p>信锐内置 Portal 和 Radius 服务，对接所有支持 Portal2.0、Radius 协议的设备，通过 Portal2.0、Radius 协议等方式对接外部标准的微软 AD 域、Oracle、MySQL、MS-SQL 以及 AS 服务等来快速方便的搭建一套高可 靠认证的无线网络。可轻松实现丰富多样的认证方式，包括人脸识别、802.1X、WAPI、CA 证书认证、Portal、 手机自注册、微信小程序、短信、APP 认证、二维码审核、Facebook 等认证方式，支持双因素安全认证。</p>
                            <p></p>
                            <p>大数据分析 </p>
                            <p>信锐下一代网络控制器通过收集和分析用户基本身份信息、来访时间、来访次数、上网时长、移动轨 迹等信息，实现用户画像。控制器还可以对用户使用的应用程序和访问的网站等上网行为，统计应用类型、 URL、热门论坛、论坛热帖排行。 信锐下一代网络控制器 Sundray Technology Co,.Ltd. 丨 2 支持人流密度分布的大数据分析，通过可视化地图，直观地呈现出无线覆盖区域内每个位置在一段时 间内经过的人流密度情况。例如：针对高职教场景，可以通过数据分析功能，加强对在校学生的日常管理， 早期预防和发现在校学生出现逃课、逃学、校园贷等风险行为。针对企业级办公场景，有助于单位决策者 分析内部相关人员的状态信息。</p>
                            <p></p>
                            <p>上网行为管理与审计</p>
                            <p>信锐下一代网络控制器内置应用识别库和千万级别海量 URL 库，可准确识别 5400 多种应用。结合内置 的应用识别库和 URL 库，实现基于用户、接入位置、时间段、终端类型等因素分配不同的上网权限策略。 同时支持审计功能，可以对用户上网行为进行记录留存，存储时长可长达 180 天，满足公安部令第 82 号、 151 号要求、新网络安全法及网监部门要求。 支持基于本地转发场景下的应用识别，通过无线 AC 和 AP 的联动，可以实现本地转发场景下的应用访 问控制、应用流量限制和审计，同时降低了无线 AC 的性能压力，有效降低选型成本的同时最大化的发挥整 体产品解决方案的价值，降低单位管理运营成本。</p>
                            <p></p>
                            <p>有线网络管理</p>
                            <p>信锐下一代网络控制器支持对有线侧进行统一管理，支持对有线用户的接入认证、访问控制、流量管 理、上网行为审计、内网有线疑似中毒终端的筛查、定位以及封堵等，并且支持对安视系列交换机和安视 系列 POE 交换机进行统一管理，一站式服务，极大的降低网络建设成本和运维管理成本。</p>
                            <p></p>
                            <p>简易的管理维护</p>
                            <p>控制器ᨀ供友好的 Web 管理界面，对所有 AP 进行统一集中管控，实现对所有 AP 的统一集中配置管理 以及网络运维。通过管理员分权分级，可以灵活的、通过网格化的管理方式增强网络管理运维的精准颗粒 度，降低单个管理员的管理运维复杂度。并且可以通过移动 APP 进行信锐下一代网络控制器的运维，通过 互联网即可方便的管理，有效的ᨀ高了网络管理员的效率。 信锐下一代网络控制器支持集群虚拟化管理，支持跨互联网远程管理各分支 AC，包括集中配置管理、 集中状态监控，并且支持异地备份，统一管理，易维护。</p>
                            <p></p>
                            <p>产品规格</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>型号</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>NAC-6100</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>无线协议</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>802.11a/b/g/n/ac/ac&nbsp;wave&nbsp;2/ax</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>接口数量</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>4个千兆电口</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>其他接口</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>1个RJ45口；</span>
                                                </p>
                                                <p>
                                                    <span>2个USB口</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>默认管理AP数量</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>8</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>最大管理AP数量</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>72/600（集中转发/本地转发）</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>最大在线并发用户数</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>4000</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>ESSID数量</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>≥100</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>内置认证账户数</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>≥65K</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>功耗</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>&lt;25w</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>电源输入</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>单电源输入</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>输入电压范围</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>90V～264V&nbsp;AC；&nbsp;47/63Hz</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>工作温度</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>-10℃～55℃</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>存储温度</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>-40℃～70℃</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>转发模式</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>本地转发/集中转发/部分本地部分集中</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>部署模式</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>旁路部署/串行部署/网关部署/远程部署</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="20.4400%" valign="top">
                                                <p>
                                                    <span>内置硬盘</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>32G&nbsp;SSD（可定制）</span>
                                                </p>
                                            </td>
                                            <td width="22.2200%" valign="top">
                                                <p>
                                                    <span>外观尺寸</span>
                                                </p>
                                            </td>
                                            <td width="35.1200%" valign="top">
                                                <p>
                                                    <span>430×300×44.5(单位：mm)</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p></p>
                            <p>无线AP接入点NAP-1600</p>
                            <p>NAP-1600无线AP是信锐技术自主研发的新一代802.11ac高性能室内无线接入点。NAP-1600室内无线AP采用内置天线设计，支持11ac/a/n和11b/g/n双频并发，整机最高速率可达733Mbps，可提供更快的无线上网和更大的无线覆盖范围。产品高达733Mbps的最大传输速率能够轻松满足各种无线业务的承载使用，如视频、语音等多媒体业务，并提供智能射频、服务质量、漫游等。NAP-1600室内无线AP支持本地供电与POE远程供电，可根据客户现场供电环境进行灵活选择。配合信锐NAC系列控制器，为用户带来快速网络体验和更安全的业务接入。NAP-1600室内无线AP外观美观大方，安装方便，适用于吸顶、壁挂以及桌面放装。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100004.png' />
                            </p>
                            <p>关键特性</p>
                            <p>1、支持802.11ac协议，整机最大速率733Mbps；</p>
                            <p>2、内置天线，同时支持信道、发射功率自动调整；</p>
                            <p>3、提供多种灵活、易用、安全的用户上网认证方式；</p>
                            <p>4、支持基于用户数、流量、频段的智能负载均衡；</p>
                            <p>5、支持本地与POE远程供电，可根据场景灵活选择；</p>
                            <p>6、支持胖瘦两种工作模式，可根据组网需要灵活切换；</p>
                            <p>7、支持NAT网关功能，并具备DHCP和DNS代理功能；</p>
                            <p>8、内置数据探针，结合网络控制器可实现客流统计分析；</p>
                            <p>9、支持外接U盘实现APP及文件缓存，节约公网带宽资源；</p>
                            <p></p>
                            <p>技术规格</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>型号</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>NAP-1600</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>无线协议</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>802.11a/b/g/n/ac</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>接口数量</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>1个以太网网口</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>其他接口</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>1个RJ45&nbsp;Console口；</span>
                                                </p>
                                                <p>
                                                    <span>1个USB口</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>工作频段</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>2.4GHz、5GHz(5.1GHz、5.8GHz)</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>整机速率</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>733Mbps</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>覆盖范围</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>半径<font face="inherit">5-15</font><font face="微软雅黑">米（视具体环境而定）</font></span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>最大接入用户数</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>256<font face="微软雅黑">（视具体环境而定）</font></span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>天线类型</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>内置天线</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>发射功率</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>100mW/20dBm</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>供电方式</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>802.3af/at标准POE供电；</span>
                                                </p>
                                                <p>
                                                    <span>本地电源12V/1A供电</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>功耗</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>&lt;10W</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>虚拟AP</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>32</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>中文SSID</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>支持</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>工作温度</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>-10℃～55℃</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>存储温度</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>-40℃～70℃</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>部署模式</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>吸顶、壁挂、放装</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>适用场景</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>小型办公室、门店、商铺</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>防护等级</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>IP41</span>
                                                </p>
                                            </td>
                                            <td width="97" valign="top">
                                                <p>
                                                    <span>外观尺寸</span>
                                                </p>
                                            </td>
                                            <td width="253" valign="top">
                                                <p>
                                                    <span>160×160×30(单位：mm)</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 6 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="rh3c" name="rh3c" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                APPLE
                            </p>
                            <p className="lstzz_ch">
                                苹果
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>iPad Pro</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100005.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100006.png' />
                            </p>
                            <p>M1 芯片，12.9 英寸 Liquid 视网膜 XDR 显示屏，超快的 5G，以及自带人物居中功能的全新超广角前置摄像头。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100007.jpg' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100008.jpg' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100009.jpg' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100010.jpg' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100011.jpg' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100012.jpg' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100013.jpg' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100014.jpg' />
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 7 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="rh3c" name="rh3c" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                HUAWEI
                            </p>
                            <p className="lstzz_ch">
                                华为
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>华为鲲鹏TaiShan通用计算服务器</p>
                            <p>TaiShan服务器是华为新一代数据中心服务器，基于华为鲲鹏处理器，适合为大数据、分布式存储、原生应用、高性能计算和数据库等应用高效加速，旨在满足数据中心多样性计算、绿色计算的需求。TaiShan 200服务器，基于鲲鹏920处理器，包含2280E边缘型、1280高密型、2280均衡型、2480高性能型、5280存储型和X6000高密型等产品型号。TaiShan 100服务器，基于鲲鹏916处理器，包含2280均衡型和5280存储型等产品型号。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100015.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100016.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100017.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100018.png' />
                            </p>
                            <p>TaiShan服务器</p>
                            <p>2280均衡型</p>
                            <p>2280是基于华为鲲鹏920处理器的2U2路均衡型服务器，系统最高能够提供128核、2.6GHz主频的计算能力和最多27个SAS/SATA HDD或SSD硬盘。</p>
                            <p>2280具有高性能、低功耗以及灵活的扩展能力等特点，适合为大数据分析、软件定义存储、Web等应用场景的工作负载进行高效加速。</p>
                            <p>高效能计算</p>
                            <p>搭载具有超强算力的鲲鹏处理器</p>
                            <p>多核计算架构，高效加速应用</p>
                            <p>安全可靠</p>
                            <p>处理器及服务器芯片全自研</p>
                            <p>17年计算工程能力铸就稳如泰山品质</p>
                            <p>开放生态</p>
                            <p>开放计算平台，支持业界主流软件</p>
                            <p>构建鲲鹏生态，共创计算新高度</p>
                            <p>技术规格</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100019.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100020.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100021.png' />
                            </p>
                            <p>华为FusionServer Pro系列机架服务器</p>
                            <p>华为FusionServer Pro系列机架服务器，适用于云计算、虚拟化、高性能计算(HPC)、数据库、SAP HANA等计算密集型场景，集成全流程高可靠性设计、BSST系统启动加速存储DEMT智慧能效、FDM智慧诊断等专利技术，可选配华为FusionDirector智能管理软件，能够有效降低运营成本、提升投资回报。</p>
                            <p>FusionServer Pro 1288H V6 机架服务器</p>
                            <p>华为FusionServer Pro 1288H V6是一款1U2路机架服务器，适用于云计算、虚拟化、高性能计算(HPC)、大数据处理等负载的高密度部署，提升数据中心空间利用率。1288H V6在1U空间里可配置2路英特尔®至强®可扩展处理器、32条DDR4内存及4*3.5"或10*2.5"的本地存储资源(可配置4/10个NVMe SSD硬盘)。 集成DEMT智能功耗管理、FDM智能故障管理等专利技术，可选配华为FusionDirector全生命周期管理软件，能够有效降低运营成本、提升投资回报。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100022.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100023.png' />
                            </p>
                            <p>FusionServer Pro 2288H V6 机架服务器</p>
                            <p>华为FusionServer Pro 2288H V6是一款2U2路机架服务器，配置灵活，可广泛适用于云计算、虚拟化、数据库、大数据等负载2288H V6可配置2路英特尔®至强®可扩展处理器、16/32条DDR4内存、14个 PCIe扩展槽、支持大容量的本地存储资源。集成DEMT智能功耗管理、FDM智能故障管理等专利技术，可选配华为FusionDirector全生命周期管理软件，能够有效降低运营成本、提升投资回报。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100024.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100025.png' />
                            </p>
                            <p>FusionServer Pro 5288 V6 机架服务器</p>
                            <p>华为FusionServer Pro 5288 V6是一款4U2路机架服务器，适用于热温冷数据分级部署、历史数据归档等业务需求，凭借高效设计，在确保卓越计算性能的同时，提供灵活、超大容量的本地存储扩展能力，降低数据存储成本。5288 V6在4U空间里可配置2路英特尔®至强®可扩展处理器、32条DDR4内存及44*3.5" +4*NVMe SSD）的本地存储资源。集成DEMT智能功耗管理、FDM智能故障管理等专利技术，可选配华为FusionDirector全生命周期管理软件，能够有效降低运营成本、提升投资回报。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100026.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100027.png' />
                            </p>
                            <p>FusionServer Pro 2488H V6机架服务器</p>
                            <p>华为FusionServer Pro 2488H V6是最新推出的2U4路机架服务器，适用于云计算、虚拟化、高性能计算(HPC)、数据库、SAP HANA等计算密集型场景。相比于2台2U2路机架服务器，在虚拟化应用中2488H V6可以带来更多的OPEX节省。2488H V6在2U空间内可配置4个第三代英特尔®至强®可扩展处理器，48条DDR4内存，11个PCIe扩展插槽以及最多25个2.5" 的本地存储资源。集成DEMT智能功耗管理、FDM智能故障管理等专利技术，可选配华为FusionDirector全生命周期管理软件，能够有效降低运营成本、提升投资回报。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100028.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100029.png' />
                            </p>
                            <p>CloudEngine S6730-H系列10GE交换机</p>
                            <p>loudEngine S6730-H系列10GE交换机，是华为公司推出的全新10GE盒式交换机，具备随板AC能力，可管理大规格数量的AP；具备业务随行能力，提供一致的用户体验；具备VXLAN能力，支持网络虚拟化功能；内置安全探针，支持异常流量检测、加密流量的威胁分析，以及全网威胁诱捕等功能，可广泛应用于企业园区、运营商、高校、政府等应用场景。</p>
                            <p>高密万兆接入</p>
                            <p>业界领先的万兆端口密度，单台设备可以最多支持48个全线速转发的万兆端口，是Wi-Fi 6上行的选择。</p>
                            <p>有线无线深度融合</p>
                            <p>支持1K无线AP管理，无线和有线的融合策略管理；突破了外置AC转发性能的瓶颈，从容面向Wi-Fi 6时代。</p>
                            <p>网络智能运维</p>
                            <p>支持Telemetry技术，实时采集设备数据，配合园区网络分析器及时发现影响用户体验的网络问题，精准保障用户体验。</p>
                            <p>技术规格</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100030.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100031.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100032.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100033.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100034.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100035.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100036.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100037.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100038.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100039.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100040.png' />
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 8 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="rh3c" name="rh3c" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                HP
                            </p>
                            <p className="lstzz_ch">
                                惠普
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p>HPE ProLiant DL360 Gen10服务器</p>
                            <p>HPE ProLiant DL360 Gen10 服务器的安全性、敏捷性及出色的性能，适用于要在空间有限的环境中部署多种工作负载的数据中心。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100041.png' />
                            </p>
                            <p>产品特性</p>
                            <p>出色的性能，多用途计算能力</p>
                            <p>HPE ProLiant DL360 Gen10 服务器支持采用行业标准技术的英特尔®至强®可扩展处理器（多达 28 核、12G SAS 和 3.0 TB 2933 MT/秒的HPE DDR4 SmartMemory）。</p>
                            <p>HPE持久性内存搭配DRAM，可打造出快速、高容量、低成本的内存和存储装置，实现数据的快速存储、移动和处理，进而助力进行大数据工作负载和分析转型。</p>
                            <p>通过配有多达10个SFF和4个LFF驱动器的灵活驱动器配置以及最多支持10个NVMe PCIe固态硬盘的选件，实现更高容量，出色的性能、容量和可靠性，并以合理的成本满足各种客户群和工作负载要求。</p>
                            <p>HPE ProLiant DL360 Gen10服务器每个机箱支持多达12个NVDIMM，而且容量是一代HPE NVDIMM的2倍，每个系统可提供多达192 GB的容量。</p>
                            <p>灵活多样的创新设计</p>
                            <p>10 SFF NVMe机箱背板以及 8+2 SFF和4 LFF机箱提供混合搭配SAS/SATA 和 NVMe的能力，可支持全新uFF 和 M.2存储选件。</p>
                            <p>提供HPE FlexibelLOM或PCIe标准网卡可选，支持1GbE、10GbE、25Gbe、100Gbe……以适应不断变换的业务需求。</p>
                            <p>支持多达3个标准PCIe插槽，提供出色的扩展性。</p>
                            <p>安全创新</p>
                            <p>只有HPE提供将主要固件直接固定在硅芯片上的工业标准服务器。借助可信硅根，内置安全保护贯穿服务器整个生命周期。</p>
                            <p>在实时的固件有效性验证机制下，几百万行固件代码在服务器操作系统启动前运行，HPE iLO高级安全版提供每24小时核心系统固件对服务器固件进行有效性和可信度验证。</p>
                            <p>安全恢复功能在检测到不良代码时允许服务器固件回退到最后一次正常状态或出厂设置。</p>
                            <p>增强安全功能包括服务器配置锁、iLO 安全仪表板和Workload Performance Advisor，可实时提供调优建议以改善性能。</p>
                            <p>TPM提供额外的安全选项，阻止未经授权的服务器访问，而通过安全存储部件的授权，当服务器机盖移开后，入侵侦测套件留下记录和告警。</p>
                            <p>世界级性能</p>
                            <p>通过智能系统优化技术创新，HPE服务器可利用自定义配置文件来调整内部资源，优化工作负载性能，对所有负载，包括时延敏感负载提供增强的性能表现，如延迟减缓高频交易。</p>
                            <p>HPE ProLiant DL360Gen10服务器支持行业标准技术，英特尔最新至强®第二代可扩展处理器系列，提供多达28个内核，12G SAS，3.0TB HPE DDR4 2933MT/s智能内存。</p>
                            <p>产品规格</p>
                            <p>HPE ProLiant DL360 Gen10 服务器</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>处理器</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>最大支持2颗英特尔®至强®第二代可扩展系列处理器，4/8/12/16/18/20/22/24/26/28个内核，最大支持功率205W</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>内存</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>最大支持24根DDR4内存插槽，速率最高支持2933MT/s，支持RDIMM或LRDIMM，最大容量3.0TB</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>永久性内存</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>支持12根HPE&nbsp;DC永久内存，最大容量6.0TB</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>存储控制器</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>标配板载阵列控制器，支持SATA&nbsp;RAID&nbsp;0/1/10/5</span>
                                                </p>
                                                <p>
                                                    <span>可选基于阵列卡专用插槽的HBA卡，支持SATA/SAS&nbsp;RAID&nbsp;0/1/10/5</span>
                                                </p>
                                                <p>
                                                    <span>可选基于阵列卡专有插槽的Smart&nbsp;Array系列阵列卡，支持SATA/SAS&nbsp;RAID0/1/10/5/6/50/60/1&nbsp;可选基于标准PCIe插槽的HBA卡和Smart&nbsp;Array阵列卡</span>
                                                </p>
                                                <p>
                                                    <span>最高支持4GB&nbsp;FBWC</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>存储</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>4&nbsp;个&nbsp;LFF&nbsp;SAS/SATA、8&nbsp;个&nbsp;SFF&nbsp;SAS/SATA&nbsp;和&nbsp;2&nbsp;个&nbsp;NVMe、10&nbsp;个&nbsp;SFF&nbsp;SAS/SATA、10个&nbsp;SFF&nbsp;NVMe、1&nbsp;个&nbsp;SFF&nbsp;或&nbsp;1&nbsp;个可选双&nbsp;UFF&nbsp;后置驱动器，具体取决于机型</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>网络</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>板载4口千兆网络适配器/网卡可选NC型号不含板载网络控制器</span>
                                                </p>
                                                <p>
                                                    <span>可选FlexibleLOM网络适配器</span>
                                                </p>
                                                <p>
                                                    <span>可选基于标准PCIe插槽的网络适配器</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>扩展插槽</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>最大3个PCIe标准插槽</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>接口</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>可选前置VGA和串口，标配后置VGA；5&nbsp;个USB&nbsp;3.0（1前置，2后置，2内置）；可选2个MicroSD</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>GPU支持</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>支持2块单宽GPU</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>光驱</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>支持外置光驱；4LFF和8SFF机型支持内置光驱</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>管理</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>标配iLO标准版，可选iLO高级版和OneView高级版</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>电源和散热</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>可选500W/800W/1600W&nbsp;1&nbsp;+&nbsp;1热拔插冗余电源，最高96%钛金,&nbsp;热插拔冗余风扇</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>工作温度</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>5℃～45℃</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>机箱尺寸</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>43.46&nbsp;x&nbsp;70.7&nbsp;x&nbsp;4.29&nbsp;cm&nbsp;（宽&nbsp;x&nbsp;深&nbsp;x&nbsp;高，SFF机型）</span>
                                                </p>
                                                <p>
                                                    <span>44.46&nbsp;x&nbsp;74.98&nbsp;x&nbsp;4.29&nbsp;cm&nbsp;（宽&nbsp;x&nbsp;深&nbsp;x&nbsp;高，LFF机型）</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>保修</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>渠道销售预配置（BTO）机型3年5×9下一工作日响应</span>
                                                </p>
                                                <p>
                                                    <span>项目销售按单配置（CTO）机型3年7×24×4小时服务，包含基础上门安装服务</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>HPE ProLiant DL380 Gen10服务器</p>
                            <p>HPE ProLiant DL380 Gen10服务器</p>
                            <p>HPE ProLiant DL380 Gen10提供性能、安全性和扩展性，成为任何业务负载环境所需的最值得信赖的计算平台。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100042.png' />
                            </p>
                            <p>产品特性</p>
                            <p>灵活设计，易于扩展：您可随着业务增长逐步增加投资</p>
                            <p>HPE ProLiant DL380 Gen10服务器拥有一个可灵活扩展的机箱，提供新的HPE模块化硬盘背板作为可配置选件，支持最多30个2.5"小盘，19个3.5"大盘，或者20个NVMe硬盘选件，同时支持最多3个双宽GPU。</p>
                            <p>HPE DC永久内存搭配 DRAM，可打造出快速、高容量、低成本的内存和存储装置， 实现数据的快速存储、移动和处理，进而助力进行大数据工作负载和分析转型。</p>
                            <p>结合集成的SATA HPE动态智能阵列控制器S100i满足启动、数据和媒体需求，经过重新设计的HPE智能阵列控制器允许用户灵活选择最适合客户环境的12Gb/s控制器，同时支持SAS接口和HBA模式。</p>
                            <p>板载的4x1GbE网络控制器/网卡可选NC型号不含板载网络控制器，您可选择 HPE FlexibleLOM或标准PCI-E的网络适配器，提供从1GbE到40GbE的网络带宽范围和构造选择，客户可适应、发展不断改变的业务需求。</p>
                            <p>除了传统的操作系统之外，还支持从Azure到Docker再到ClearOS广泛的操作环境。</p>
                            <p>安全创新</p>
                            <p>只有HPE提供将主要固件直接固定在硅芯片上的工业标准服务器。借助可信硅根，内置安全保护贯穿服务器整个生命周期。</p>
                            <p>在实时的固件有效性验证机制下，几百万行固件代码在服务器操作系统启动前运行，HPE iLO高级安全版提供每24小时核心系统固件对服务器固件进行有效性和可信度验证。</p>
                            <p>安全恢复功能在检测到不良代码时允许服务器固件回退到最后一次正常状态或出厂设置。</p>
                            <p>增强安全功能包括服务器配置锁、iLO 安全仪表板和Workload Performance Advisor，可实时提供调优建议以改善性能。</p>
                            <p>TPM提供额外的安全选项，阻止未经授权的服务器访问，而通过安全存储部件的授权，当服务器机盖移开后，入侵侦测套件留下记录和告警。</p>
                            <p>世界级性能</p>
                            <p>通过智能系统优化技术创新，HPE服务器可利用自定义配置文件来调整内部资源，优化工作负载性能，对所有负载，包括时延敏感负载提供增强的性能表现，如延迟减缓高频交易。</p>
                            <p>HPE ProLiant DL380Gen10服务器支持行业标准技术，英特尔最新至强®第二代可扩展处理器系列，提供多达28个内核，12G SAS，3.0TB HPE DDR4 2933MT/s智能内存。</p>
                            <p>HPE ProLiant DL380 Gen10支持最多3个双宽或7个单宽GPU加速工作负载。</p>
                            <p>产品规格</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>处理器</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>最大支持2颗英特尔®至强®第二代可扩展系列处理器，4/8/12/16/18/20/22/24/26/28个内核，最大支持功率205W</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>内存</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>最大支持24根DDR4内存插槽，速率最高支持2933MT/s，支持RDIMM或LRDIMM，最大容量3.0TB，</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>永久性内存</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>支持12根NVDIMM永久性内存，最大容量192GB/HPE&nbsp;DC永久内存最大支持6TB</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>存储控制器</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>标配板载阵列控制器，支持SATA&nbsp;RAID&nbsp;0/1/10/5</span>
                                                </p>
                                                <p>
                                                    <span>可选基于阵列卡专用插槽的HBA卡，支持SATA/SAS&nbsp;RAID&nbsp;0/1/10/5</span>
                                                </p>
                                                <p>
                                                    <span>可选基于阵列卡专有插槽的Smart&nbsp;Array系列阵列卡，支持SATA/SAS&nbsp;RAID0/1/10/5/6/50/60/1&nbsp;ADM&nbsp;可选基于标准PCIe插槽的HBA卡和Smart&nbsp;Array阵列卡</span>
                                                </p>
                                                <p>
                                                    <span>最高支持4GB&nbsp;FBWC</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>存储</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>24+6个&nbsp;SFF/12LFF&nbsp;+&nbsp;4+3&nbsp;个&nbsp;SFF+最大&nbsp;2&nbsp;个&nbsp;SFF&nbsp;HDD/SSD硬盘、20&nbsp;个&nbsp;NVMe&nbsp;PCIe&nbsp;SSD&nbsp;和+&nbsp;m.2&nbsp;可</span>
                                                </p>
                                                <p>
                                                    <span>用扩展</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>网络</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>板载4口千兆网络适配器/网卡可选NC型号不含板载网络控制器</span>
                                                    <span>6</span>
                                                </p>
                                                <p>
                                                    <span>可选FlexibleLOM网络适配器</span>
                                                </p>
                                                <p>
                                                    <span>可选基于标准PCIe插槽的网络适配器</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>扩展插槽</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>标配3个PCIe&nbsp;3.0可用插槽，最大8个PCIe可用插槽</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>接口</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>可选前置VGA和串口，标配后置VGA；5个USB&nbsp;3.0（1前置，2后置，2内置）；可选2个MicroSD</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>GPU支持</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>多达（7&nbsp;个）单宽/（3&nbsp;个）双宽</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>光驱</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>支持外置光驱；8LFF和8SFF机型支持内置光驱</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>管理</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>标配iLO标准版，可选iLO高级版和OneView高级版</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>电源和散热</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>可选500W/800W/1600W&nbsp;1&nbsp;+&nbsp;1热拔插冗余电源，最高96%钛金,&nbsp;热插拔冗余风扇</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>工作温度</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>5℃～45℃，ASHRAE&nbsp;A3&nbsp;&amp;&nbsp;A4</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>机箱尺寸</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>44.54&nbsp;x&nbsp;67.94&nbsp;x&nbsp;8.73&nbsp;cm&nbsp;（宽&nbsp;x&nbsp;深&nbsp;x&nbsp;高，SFF机型）</span>
                                                </p>
                                                <p>
                                                    <span>44.54&nbsp;x&nbsp;73.02&nbsp;x&nbsp;8.73&nbsp;cm&nbsp;（宽&nbsp;x&nbsp;深&nbsp;x&nbsp;高，LFF机型）</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="24.1200%" valign="top">
                                                <p>
                                                    <span>保修</span>
                                                </p>
                                            </td>
                                            <td width="75.8800%" valign="top">
                                                <p>
                                                    <span>渠道销售预配置（BTO）机型3年5×9下一工作日响应</span>
                                                </p>
                                                <p>
                                                    <span>项目销售按单配置（CTO）机型3年7×24×4小时服务，包含基础上门安装服务</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>HPE ProLiant DL580 Gen10服务器</p>
                            <p>HPE ProLiant DL580 Gen10服务器采用4U机箱，是一台安全高度可扩展的4P服务器，具有高性能、可扩展性和可用性。</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100043.png' />
                            </p>
                            <p>产品特性</p>
                            <p>可扩展的4U服务器的可扩展性能</p>
                            <p></p>
                            <p>完全重新设计的HPE ProLiant DL580 Gen10服务器具有可扩展的4U外形，提供4P 计算，支持多达四个Intel®Xeon®铂金级和黄金级处理器。与上一代产品相比，该处理器的性能提高达28%，内核增加达17%。</p>
                            <p></p>
                            <p>多达48个DIMM槽支持最多6TB的2933MT/s HPE DDR4 SmartMemory。HPE DDR4 SmartMemory在提高工作负载性能和电源效率的同时，通过增强的错误处理能力减少了数据丢失和停机时间。</p>
                            <p></p>
                            <p>HPE FlexibleLOM适配器有众多选择,可提供各种网络速度 (1GbE~25GbE) 和结构， 使您能够适应时刻变化和增长的业务需求。</p>
                            <p></p>
                            <p>智能系统调优 (Intelligent System Tuning) 带来的HPE创新，通过使用自定义配置文件进行内部资源优化，提升了工作负载性能，提高了所有工作负载 (包括延迟敏感型工作负载，例如通过对抖动进行平滑而实现的高频交易) 的吞吐量。</p>
                            <p></p>
                            <p>对多个工作负载具有显著的可扩展性和可用性</p>
                            <p></p>
                            <p>HPE ProLiant DL580 Gen10服务器有一个灵活的处理器托盘，使服务器可以根据需要从一个处理器扩展到四个，节省了前期成本；它还设计了一个灵活的驱动器托架， 支持多达48个小尺寸 (SFF) SAS/SATA驱动器和多达20个NVMe驱动器。</p>
                            <p></p>
                            <p>该服务器支持多达16个PCIe 3.0扩展插槽，包括多达4个全长/全高的图形处理单元(GPU) 以及可提供更高可扩展性的网络卡或存储控制器。</p>
                            <p></p>
                            <p>该服务器还支持多达4个能效为96%的HPE 800W或1600W灵活插槽电源，通过2+2 配置和灵活的电压范围，实现了更高的功率冗余。</p>
                            <p></p>
                            <p>安全可靠</p>
                            <p></p>
                            <p>HPE提供具有其自己设计的BMC (HPE iLO5) 硅片的行业标准服务器。该硅片进行了物理上的改变，以保护大部分服务器基本固件，包括HPE iLO、UEFI、IE、ME和SPLD。对HPE硅片的定位创造了真正具有保护意义的硅信任根 (可信硅根)。</p>
                            <p></p>
                            <p>在服务器操作系统启动和运行环境固件验证之前，HPE iLO Advanced Premium Security Edition使数百万行的固件代码开始运行，服务器固件每24小时检查一次， 验证基本系统固件的有效性和可信度。</p>
                            <p></p>
                            <p>安全恢复 (Secure Recovery) 允许服务器固件在检测到泄漏的代码后回滚到最后一次已知的良好状态或出厂设置。</p>
                            <p></p>
                            <p>可信平台模块 (TPM/TCM) 提供额外的安全选项，以防止未经授权访问服务器；并在移除服务器上盖时触发入侵检测包 (Intrusion Detection Kit) 登录并报警，确保存储在服务器的组件的安全。</p>
                            <p></p>
                            <p>加速IT服务交付的敏捷的基础设施管理</p>
                            <p></p>
                            <p>HPE ProLiant DL580 Gen10服务器与HPE OneView软件的结合，为跨服务器、存储和网络的自动操作简化管理提供基础设施。</p>
                            <p></p>
                            <p>服务器生命周期管理可使用一套嵌入式的、可下载的工具，包括统一可扩展固件接口 (UEFI)、智能资源调配 (Intelligent Provisioning)；用于监控和管理的HPE iLO 5； HPE iLO Amplifier Pack 、 Smart Update Manager (SUM) 和 Service Pack for ProLiant (SPP)。</p>
                            <p></p>
                            <p>产品规格</p>
                            <p>
                                <table border="1" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>外形</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>外形</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>处理器</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>Intel®Xeon®可扩展处理器</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>处理器数量</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>4/3/2/1个</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>处理器可用内核</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>每个处理器28/26/24/22/20/18/16/14/12/10/8/6/4个内核，具体取决于型号</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>处理器缓存</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>每个处理器13.75MB&nbsp;L3/16.50MB&nbsp;L3/19.25MB&nbsp;L3/22.00MB&nbsp;L3/24.75MB&nbsp;L3/27.50MB&nbsp;L3/30.25MB&nbsp;L3/33.00MB&nbsp;L3/</span>
                                                </p>
                                                <p>
                                                    <span>35.75MB&nbsp;L3/38.50MB&nbsp;L3，具体取决于型号</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>处理器速度</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>最高3.6GHz，具体取决于处理器</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>电源类型</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>最多4个HPE灵活插槽电源，具体取决于型号。支持240VDC高压直流</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>扩展插槽</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>最多16个全高PCIe插槽，支持4个双宽GPU，请参阅配置指南了解详细信息</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>最大内存</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>最大6.0TB，具体取决于处理器</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>内存插槽</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>最多48个DIMM&nbsp;插槽</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>内存类型</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>HPE&nbsp;DDR4&nbsp;SmartMemory，可选24个NVDIMM永久内存，支持24个DCPMM持久内存</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>硬盘驱动器</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>最多支持48个SFF硬盘，支持SATA/SAS/SSD，包括支持20个NVMe&nbsp;SSD硬盘</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>系统风扇特征</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>12&nbsp;(11+1)&nbsp;热插拔冗余标准</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>网络控制器</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>选配的FlexibleLOM，支持1Gb/10Gb/25Gb，可选PCIe网卡可支持最高100Gb网络</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>存储控制器</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>HPE&nbsp;Smart&nbsp;Array&nbsp;S100i或HPE智能阵列控制器，具体取决于型号，支持最高4GB缓存</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>最小尺寸&nbsp;(宽×深×高)</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>17.48×44.55×75.18cm</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>重量</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>51.71kg</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>基础设施管理</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>包括具有Intelligent&nbsp;Provisioning&nbsp;(内嵌)&nbsp;的HPE&nbsp;iLO标准版以及HPE&nbsp;OneView标准版&nbsp;(需要下载)</span>
                                                </p>
                                                <p>
                                                    <span>选配：HPE&nbsp;iLO&nbsp;Advanced、HPE&nbsp;iLO&nbsp;Advanced&nbsp;Premium&nbsp;Security&nbsp;Edition和HPE&nbsp;OneView&nbsp;Advanced&nbsp;(可选择许可证)</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="21.6000%" valign="top">
                                                <p>
                                                    <span>标准保修</span>
                                                </p>
                                            </td>
                                            <td width="78.4000%" valign="top">
                                                <p>
                                                    <span>渠道销售预配置（BTO）机型3年5×9下一工作日响应，</span>
                                                </p>
                                                <p>
                                                    <span>项目销售按单配置（CTO）机型3年7x24x4服务，包含基础上门安装服务</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 9 ? <div className="cp_box cp_fff" style={{ height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll" }}>
                    <a className="anchor" id="rh3c" name="rh3c" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                MAXHUB
                            </p>
                            <p className="lstzz_ch">
                                MAXHUB
                            </p>
                        </div>
                    </div>
                    <div className="wh_box">
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片1.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片2.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片3.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片4.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片5.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片6.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片7.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片8.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片9.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片10.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片11.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片12.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片13.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片14.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片15.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片16.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片17.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片18.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片19.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片20.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片21.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片22.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片23.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片24.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片25.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片26.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片27.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片28.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片29.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片30.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片31.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片32.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片33.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片34.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片35.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片36.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片37.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片38.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片39.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片40.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片41.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片42.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片43.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片44.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片45.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片46.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片47.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片48.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/幻灯片49.png' />
                            </p>
                        </div>
                    </div>
                </div> : null
            }

            <FooterBar />

        </div>
    );
}

export default Products;
