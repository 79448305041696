/* eslint-disable no-script-url */
/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function FooterBar() {
    return (
        <div className="footer">
            <div className="part_box">
                <div className="t clearfix">
                    <dl className="aa" style={{marginBottom: 0}}>
                        <dt>联系电话</dt>
                        <dd>电话号码：021－62577833</dd>
                    </dl>
                    <dl className="ab" style={{marginBottom: 0}}>
                        <dt>联系地址</dt>
                        <dd>办公地址：上海市普陀区常德路1339号金昌商务中心A座401室</dd>
                        <dd>邮件编码：200060</dd>
                    </dl>
                    <dl className="ac" style={{marginBottom: 0}}>
                        <dt>联系邮箱</dt>
                        <dd>邮箱地址：info@eltechcorp.com</dd>
                    </dl>
                </div>
                <div className="f clearfix" style={{height: 32}}>
                    <p className="fl">Copyright &copy; 2021. <i>eLT</i> All Rights Reserved.</p>
                    <p className="fr">备案号：<a rel="nofollow" style={{ color: "#fff" }} target="_blank" href="https://beian.miit.gov.cn/"><img src="http://images.9k9k.com/template/img/beiann.png" style={{ position: "relative", top: -1 }} />沪ICP备2020038399号-1</a></p>
                </div>
            </div>
        </div>
    );
}

export default FooterBar;
