/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import NavigationBar from './NavigationBar';
import FooterBar from './FooterBar';
import './App.css';

function Anli() {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    return (
        <div style={{height: "100vh"}}>

            <NavigationBar current={'方案'} />

            <div className="my_banner banner1">
                <div className="inf">
                    <h3>
                    方案
		            </h3>
                    <h4>
                    SOLUTION
		            </h4>
                </div>
            </div>
            <div className="cp_t">
                <div className="cp_t_a">
                    <a style={{ color: currentIndex === 0 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(0)}>生物制药服务器机房&布线</a>
                    <a style={{ color: currentIndex === 1 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(1)}>Big digital项目</a>
                    <a style={{ color: currentIndex === 2 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(2)}>高性能计算</a>
                    <a style={{ color: currentIndex === 3 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(3)}>银行虚拟化整合</a>
                    <a style={{ color: currentIndex === 4 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(4)}>云管理平台</a>
                    <a style={{ color: currentIndex === 5 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(5)}>超融合方案</a>
                </div>
            </div>

            {
                currentIndex === 0 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <a className="anchor" id="wlyx" name="wlyx" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                Biopharmaceutical server room & wiring
			            </p>
                            <p className="lstzz_ch">
                                生物制药服务器机房&布线
			            </p>
                        </div>
                    </div>
                    <div className="wh_box" style={{ marginBottom: 50 }}>
                        <div className="ab_all">
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt35131.png' />
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 1 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <a className="anchor" id="wxxyx" name="wxxyx" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                Big digital Project
			            </p>
                            <p className="lstzz_ch">
                                Big digital项目
			            </p>
                        </div>
                    </div>
                    <div className="wh_box" style={{ marginBottom: 50 }}>
                        <div className="ab_all">
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt35451.png' />
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 2 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <a className="anchor" id="yxmt" name="yxmt" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                High Performance Computing
				            </p>
                            <p className="lstzz_ch">
                                高性能计算
				            </p>
                        </div>
                    </div>
                    <div className="wh_box" style={{ marginBottom: 50 }}>
                        <div className="ab_all">
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt13560.png' />
                            </p>
                            <p>
                                集群包含65台HS23刀片服务器作为计算节点，分别配置6台与4台x3650M3机架服务器作管理登录
                                和存储节点，配置2台dx360M3图形服务器，采用NVida quadro5000图形加速卡。所有节点间通
                                过40Gb Infiniband高速网络连接，整套系统的峰值双精度浮点运算能力将近每秒21.6万亿次，
                                总体功耗小于或等于38kW。
                        </p>
                            <p>集群内部网络连接主要包括3个网络</p>
                            <p>1、管理网络</p>
                            <p>2、 IB高性能交换网络</p>
                            <p>3、百兆登录集群使用网络</p>
                            <p>软件系统</p>
                            <p>Xcat：通过单点登录管理集群</p>
                            <p> 操作系统：Red Hat Enterprise Linux Server release 6.2</p>
                            <p> 并行文件系统：GPFS3.4.0高性能、可扩展的并行文件系统，可以在群集中的多个节点间实现对共享文件系统中文件的快速存取操作，并提供稳定的故障恢复和容错机制。</p>
                            <p> 调度系统：LSF7.0 ，配置 Platform公司的LSF高性能计算集群调度软件，能够提供丰富的任务调度策略，满足不同用户习惯的使用，和现有集群环境实现良好的集成。</p>
                            <p> PE： MPI并行环境。</p>
                            <p> C/C++ Compiler：intel C/C++语言编译环境。在各节点安装</p>
                            <p> Fortran Compiler：intel Fortran语言编译环境。在各节点安装</p>
                            <p> 应用软件：Fluent: 14.0 LS-Dyna 971_R5.1.1</p>
                            <p> 应用：计算卡车车身外部流场分布，三车碰撞过程</p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 3 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <a className="anchor" id="rjyy" name="rjyy" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                Bank virtualization integration
			            </p>
                            <p className="lstzz_ch">
                                银行虚拟化整合
			            </p>
                        </div>
                    </div>
                    <div className="wh_box" style={{ marginBottom: 50 }}>
                        <div className="ab_all">
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt42061.png' />
                            </p>
                            <p>
                                银行的 IT 环境中应用系统众多，支持各种应用的数据库系统数量急剧增加，导致系统
                                架构臃肿，单个系统的资源利用率低下，系统架构缺乏弹性，同时管理难度增加，管理成本
                                也会大幅增加。
                        </p>
                            <p>为了降低管理成本，提高系统的资源利用率，方案通过在服务器集中的基础上通过虚拟
                            化的技术将主机、存储等资源以资源池的方式统一管理和分配，为数据库系统提供计算和存
储资源。 在对数据库系统实现集中和统一管理的同时，需要满足不同应用系统的需求。</p>
                            <p>收益</p>
                            <p>通过采用eLT提供的虚拟化、云计算解决方案，构建了一个安全、可
                            靠、稳定、灵活管理的基础架构平台，以满足现在及未来业务增长的需要。 </p>
                            <p>部署环境</p>
                            <p>VMware 解决方案</p>
                            <p> VMware vspher 6.0 企业增强版</p>
                            <p> VMware vRealize Automation</p>
                            <p> VMware vRealize Operations</p>
                            <p> VMware vRealize Business for Cloud</p>
                            <p> VMware DRS</p>
                            <p> VMware VMotion</p>
                            <p>主要硬件</p>
                            <p> IBM X3850X5、 X3850X6、 x3650M4 、 x3650M5</p>
                            <p> IBM V7000</p>
                            <p> EMC vplex 、 VNX5200、 VNX5600</p>
                            <p> NetApp FAS8020</p>
                            <p> CISCO MDS</p>
                            <p>虚拟化应用</p>
                            <p> 生产环境及测试环境</p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 4 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <a className="anchor" id="thinkcloud" name="thinkcloud" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                Cloud Management Platform
				            </p>
                            <p className="lstzz_ch">
                                云管理平台
				        </p>
                        </div>
                    </div>
                    <div className="wh_box" style={{ marginBottom: 50 }}>
                        <div className="ab_all">
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt49061.png' />
                            </p>
                            <p>
                                应用场景
                        </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt49141.png' />
                            </p>
                            <p>方案概述</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt49211.png' />
                            </p>
                            <p>方案特性</p>
                            <p></p>
                            <p>自助式服务按需交付</p>
                            <p>提供虚拟数据中心、虚拟主机、物理主机、云应用、负载均衡和大数据服务等多种类型的云服务、支持服务自助申请、即时交付、按需使用、按量计费等智能化交付。</p>
                            <p>图形化应用快速部署</p>
                            <p>提供图形化应用定义工具，支持软件的自动化安装，极大简化业务应用与软件系统的部署安装过程，大幅缩短业务上线时间，提升业务敏捷性。</p>
                            <p>智能化业务弹性支撑提供开放式、易扩展的自动弹性策略框架，以业务状态监控数据为依据，实现业务支撑环境负载变化而智能伸缩，有效保障业务健康稳定运行。</p>
                            <p>多层次资源集中监管提面向分布式动态数据中心，提供私有云/公有云/混合云环境的全方位、多层次立体监管；支持多种虚拟化环境，实现集中监管和调度；</p>
                            <p>自动化综合运维管理以精细化的资源监控为基础，以智能化的策略控制为手段，提供实施告警、快速定位、日志分级管理及检索，进而支撑面向问题快速修复，保障动态数据中心监控运行。</p>
                            <p>全方位综合分析报告提供数据中心容量预测，前瞻性预防业务及数据扩张引发的资源枯竭；提供数据中心资源使用状况综合分析报告，全面展示运行情况，助力决策。</p>
                            <p></p>
                            <p>用户收益</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt53961.png' />
                            </p>
                            <p>存储业务连续性方案</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt54091.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt54111.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt54141.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt54171.png' />
                            </p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt54201.png' />
                            </p>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 5 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <a className="anchor" id="thinkcloud" name="thinkcloud" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                            Supper integration solution
				            </p>
                            <p className="lstzz_ch">
                            超融合方案
				        </p>
                        </div>
                    </div>
                    <div className="wh_box" style={{ marginBottom: 50 }}>
                        <div className="ab_all">
                            <p>
                            项目背景
                            </p>
                            <p>国内领先的防爆电器、专业照明设备供应商，主要从事防爆电器、专业照明的研发、生产和销售。
随着集团规模的不断扩大，通过信息化建设加速应用，加快系统响应时间，提升业务处理能力；产品性能优异、稳定、可靠，业务连续性；减少运维时间及成本；降低耗电量及空间占用；解决单点问题，构建高可用的核心系统；扩容方便，满足未来5年业务发展需求</p>
                            <p></p>
                            <p>挑战和问题</p>
                            <p>目前公司多个品牌的IT系统独立运行，有多家供应商为公司提供服务。供应商是IBM、Lenovo、Dell等多家不同服务器、存储厂商。
核心存储设备老化，已使用5年，性能严重不足，影响应用体验
多个系统独立运行，没有整体的数据安全方案，尤其是核心业务系统无法满足业务部门的要求，一旦发生严重故障，后果不堪设想。
多系统并行运行，运营成本高运行效率差，IT系统重复建设严重，没有统一的资源管理平台</p>
                            <p></p>
                            <p>Lenovo HX超融合架构</p>
                            <p>3个超融合节点，满足业务连续性和性能要求,随着业务增长，未来可无限制扩容不同配置的节点，加速应用，加快系统响应时间，提升业务处理能力</p>
                            <p style={{ display: "flex" }}>
                                <img style={{ margin: "auto" }} src='/img/elt100002.png' />
                            </p>
                            <p>收益</p>
                            <p>通过部署Lenovo HX超融合，帮助公司改善并提高IT基础架构性能，提升业务处理效率；
建立高可用架构，保障业务7×24小时不间断运行；减少运维人员日常维护负担，降低运维复杂度；降低成本（运维、能耗、机房空间……）
</p>
                        </div>
                    </div>
                </div> : null
            }

            <FooterBar />

        </div>
    );
}

export default Anli;
