/* eslint-disable no-script-url */
/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function NavigationBar(props) {
  const [lang, setLang] = React.useState('zh');

  React.useEffect(() => {
    var mylang = localStorage.getItem("elt-lang");
    if (mylang) {
      setLang('en');
    }
  }, []);

  return (
      <div className="top_wrap" style={{height: 105}}>
        <div className="top_box clearfix">
          <a className="logo" href="/" title="上海良因"></a>
          <ul className="nav">
            <li className="nav_list">
              <a href="/" className={props.current === "首页" ? "current" : ""}>{lang === 'zh' ? '首页' : 'Home'}</a>
            </li>
            <li className="nav_list">
              <a href="/products" className={props.current === "产品" ? "current" : ""}>{lang === 'zh' ? '产品' : 'Products'}</a>
            </li>
            <li className="nav_list">
              <a href="/service" className={props.current === "服务" ? "current" : ""}>{lang === 'zh' ? '服务' : 'Service'}</a>
            </li>
            <li className="nav_list">
              <a href="/anli" className={props.current === "方案" ? "current" : ""}>{lang === 'zh' ? '方案' : 'Solution'}</a>
            </li>
            <li className="nav_list">
              <a href="/about" className={props.current === "关于" ? "current" : ""}>{lang === 'zh' ? '关于' : 'About'}</a>
            </li>
            <li className="nav_list">
              <a href="/lxfs/" className={props.current === "我们" ? "current" : ""}>{lang === 'zh' ? '我们' : 'US'}</a>
            </li>
          </ul>
          {
            lang === 'zh' ? <div style={{position: "absolute", display: 'flex', flexDirection: "row", right: 0}}>
              <a onClick={() => {setLang('en'); localStorage.setItem("elt-lang", "en")}}>en</a>&nbsp;&nbsp;/&nbsp;&nbsp;<p>中文</p>
            </div> : <div style={{position: "absolute", display: 'flex', flexDirection: "row", right: 0}}>
              <p>en</p>&nbsp;&nbsp;/&nbsp;&nbsp;<a onClick={() => {setLang('zh'); localStorage.removeItem("elt-lang")}}>中文</a>
            </div>
          }
        </div>
      </div>
  );
}

export default NavigationBar;
