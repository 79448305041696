/* eslint-disable no-script-url */
/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import NavigationBar from './NavigationBar';
import FooterBar from './FooterBar';
import './App.css';

function App() {

  const [winWidth, setWinWidth] = React.useState(0);

  React.useEffect(() => {
    setWinWidth(document.body.clientWidth);

    window.addEventListener('resize', () => {
      setWinWidth(document.body.clientWidth);
    }, false);

    // var img = document.getElementById("img");
    // var fangkuai = document.getElementById("fangkuai");
    // var a = fangkuai.getElementsByTagName("a");
    // var p = 0;//控制循环
    // var timer = null;//时钟变量
    // var x = 1;//左右移动
    // var index = 0;

    // function allElements() {
    //   for (var k = 0; k <= a.length - 1; k++) {
    //     a[k].className = "";  // 去掉类，复原
    //   }
    // }
    // function Slider() {//自动播放
    //   p += x;
    //   ((p === 0) || (p === 2)) && (x = -x);
    //   allElements();
    //   a[p].className = "first";
    //   img.style.marginLeft = -(winWidth * p + 1 * p) + "px";
    // }

    // timer = setInterval(Slider, 2000);
    // for (var i = 0; i <= a.length - 1; i++) {
    //   a[i].onmouseover = function () {
    //     allElements();
    //     this.className = "first";  //  突出当前标签
    //     index = this.getAttribute("index");
    //     img.style.marginLeft = -(winWidth * index + 1 * index) + "px";
    //     clearInterval(timer);
    //   }
    //   a[i].onmouseout = function () {
    //     timer = setInterval(Slider, 2000);
    //     p = parseInt(index);
    //   }
    // }
  }, []);

  return (
    <div>

      <NavigationBar current={'首页'}/>

      <div id="myCarousel" class="carousel slide">
        <ol class="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="item active">
            <img src="/img/banner_123.png" alt="First slide" />
          </div>
          <div class="item">
            <img src="/img/banner_2.png" alt="Second slide" />
          </div>
          <div class="item">
            <img src="/img/banner_05.png" alt="Third slide" />
          </div>
        </div>
        <a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
          <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
          <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>

      <div className="kouhao"><div className="kh_box"></div></div>
      <div className="part part1">
        <div className="part_box clearfix">
          <div className="l fl">
            <div className="tit tit_white"><i></i><span>企业简介</span><a href="http://www.leshu.com.cn/about#qyjj">more+</a></div>
            <div className="cont">
              <p>上海良因信息技术有限公司一家具有丰富IT行业经验和服务理念的信息技术型公司，与这个日新月异，不断创新的IT行业共同成长。成立以来，良因公司以客户需求为主导，逐步构建了公司经营的产品导向，现签约多家世界一流IT品牌Lenovo、HP、DELL EMC、Cisco、Microsoft等，并成为其经销商或合作伙伴。</p>
              <p>上海良因信息技术有限公司核心团队具有丰富的IT从业经验和敏锐的市场洞察力，使公司在业务、技术、服务、管理等方面不断攀升，同时不忘营造良好的企业文化，提升企业凝聚力。</p>
            </div>
          </div>
          <div className="r fr">
            <div className="tit tit_white"><i></i><span>公司环境</span><a href="http://www.leshu.com.cn/wenhua/#gshj">more+</a></div>
            <div className="cont clearfix">
              <img src="./img/elt_logo.png" width="281" height="281" />
              {/* <img src="./img/elt_logo.png" width="281" height="281" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cp_box cp_fff" style={{paddingBottom: 0}}>
        <div className="wrapper">
          <div className="content_index">
            <h1>
              我们为全球用户提供优质服务
			      </h1>
            <h2 style={{textAlign: "center", marginBottom: 50}}>
              欢迎各位惠顾
            </h2>
          </div>
        </div>
        
        <FooterBar />
      
      </div>
    </div>
  );
}

export default App;
