/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import NavigationBar from './NavigationBar';
import FooterBar from './FooterBar';
import './App.css';

function Service() {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    React.useEffect(() => {
    }, []);

    return (
        <div style={{height: "100vh"}}>

            <NavigationBar current={'服务'} />

            <div className="my_banner banner2">
                <div className="inf">
                    <h3>
                    服务
		            </h3>
                    <h4>
                    SERVICE
		            </h4>
                </div>
            </div>
            <div className="cp_t">
                <div className="cp_t_a">
                    <a style={{ color: currentIndex === 0 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(0)}>服务范围</a>
                    <a style={{ color: currentIndex === 1 ? 'yellow' : 'white' }} onClick={() => setCurrentIndex(1)}>服务内容</a>
                </div>
            </div>
            
            {
                currentIndex === 0 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <div className="wrapperw3">
                        <a className="anchor" id="qyjj" name="qyjj" href="#"></a>
                        <div className="lstzz">
                            <div className="lstzz_a">
                                <p className="lstzz_en">
                                    service
				            </p>
                                <p className="lstzz_ch">
                                    服务范围
				            </p>
                            </div>
                        </div>
                        <div className="wh_box" style={{ marginBottom: 50 }}>
                            <div className="ab_all">
                                <p style={{ textIndent: 0 }}>一、一体化的技术支持服务</p>
                                <p>
                                    eLT根据客户的IT运行现状，可以给予优化建议，从规划到实施，提供端到端的整体服务解决方案。专业的复杂项目管理能力帮助客户缩短服务窗口,保障业务高可用性和连续性。通过单一接口为客户提供满足业务要求的，一体化的技术支持服务
                            </p>
                                <p style={{ textIndent: 0 }}>二、IT基础运维服务</p>
                                <p>
                                    在快速变化的商业环境中，对自动化流程及其背后的计算机系统的依赖程度不断上升。系统不仅仅要全天候可用，还必须为客户信息和公司数据提供保护和安全性服务。服务器与数据中心就像企业IT的心脏，保障着企业IT基础架构及应用的正常运转。eLT可以为用户提供服务器安装、部署及升级服务，服务器管理服务，服务器优化服务以及相关的技术支持及维护服务。eLT服务器及数据中心服务为业务关键性应用制定可靠、灵活而且高效率的IT基础架构，这种架构可以有效支撑新的业务营销计划的执行、产品和增长提供支持。
				            </p>
                                <p style={{ textIndent: 0 }}>三、第三方软件支持服务</p>
                                <p>
                                    为企业级用户倾力打造系统软件支持服务，包括 Windows Server、Linux、VMware、 Sure HA、 Oracle DB、SQL Server、Exchange Server 的电话、Email 及远程支持服务。
				            </p>
                                <p>
                                    不仅拥有企业级硬件服务，客户还可获得操作系统、虚拟化、HA和数据库系统软件的服务支持及技术方案。
				            </p>
                                <p>
                                    eLT提供用户操作系统、虚拟化软件、数据库在线软硬专家服务。拥有经认证与授权的工程师及专家团队。高度整合业界资源，服务质量和时效更可靠 。系统软件如有发生问题，提供一站式技术支持。
                            </p>
                                <p style={{ textIndent: 0 }}>四、完善的技术支持团队</p>
                                <p>公司非常注重IT服务团队的建设和员工培养，核心技术专家具有各大主流原厂商资质认证，以及原厂商授权的服务供应商集成商中吸收了一部分技术突出的中坚人才同时自主培养了一部分新生IT技术力量以充实整个技术团队。</p>
                                <p>eLT支持服务团队有一线责任工程师团队，二线专家团队、三线资深专家团队组成。当一线责任工程师无法解决问题时，将迅速启动问题升级流程，二线专家团队将通过远程或现场支持等方式协助一线工程师解决故障恢复系统。当二线专家团队也因某种原因无法解决问题时，eLT将迅速再次启动问题升级流程，将故障升级至三线资深专家团队。</p>
                                <p style={{ textIndent: 0 }}>五、技术服务的方式</p>
                                <p style={{ textIndent: 0 }}>1、电话支持</p>
                                <p>eLT支持部在接到用户的支持请求或故障报告后，将会在第一时间内以电话方式同用户取得联系，了解系统问题的详细情况，对于无法立即解决的，技术问题会记入我公司的客户报告系统，并将告诉用户预计的答复日期和时间。</p>
                                <p style={{ textIndent: 0 }}>2、现场响应</p>
                                <p>当用户软硬件系统在遇到重大故障时，实施预先制定的应急处理方案和工作计划，确保处于故障状态下的用户生产业务在最短时间内得到恢复。根据用户的实际系统配置，与用户相配合，制定重大故障状态的应急备份切换方案，确保将故障对用户生产活动的影响减少到最少程度。</p>
                                <p style={{ textIndent: 0 }}>3、日常性维护及定期检查</p>
                                <p>eLT工程师会利用巡检的机会对用户提出的实际工作中遇到的技术问题进行咨询和辅导，同时根据用户系统实际应用情况，对系统的关键性性能进行优化。</p>
                                <p style={{ textIndent: 0 }}>六、客户服务流程图</p>
                                <p>
                                    <img src='/img/elt24101.png' />
                                </p>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            {
                currentIndex === 1 ? <div className="cp_box cp_fff" style={{height: "calc(100% - 181px)", overflowX: "hidden", overflowY: "scroll"}}>
                    <a className="anchor" id="fzlc" name="fzlc" href="#"></a>
                    <div className="lstzz">
                        <div className="lstzz_a">
                            <p className="lstzz_en">
                                serivce content
			            </p>
                            <p className="lstzz_ch">
                                服务内容
			            </p>
                        </div>
                    </div>
                    <div className="wh_box" style={{ marginBottom: 50 }}>
                        <div className="ab_all" style={{ paddingTop: 0 }}>
                            <p style={{ textIndent: 0 }}>1、项目实施及管理</p>
                            <p>数据中心的规划与实施、高性能计算系统的实施、部署</p>
                            <p style={{ textIndent: 0 }}>2、技术咨询与服务</p>
                            <p>虚拟化和云计算的咨询和服务</p>
                            <p style={{ textIndent: 0 }}>3、移动平台业务解决方案</p>
                            <p>移动设备安装、部署拥有专业的移动平台应用团队，为客户提供全方位的解决方案和定制开发服务</p>
                            <p style={{ textIndent: 0 }}>4、Helpdesk解决方案</p>
                            <p>从呼叫中心到现场支持的一揽子解决方案</p>
                            <p style={{ textIndent: 0 }}>5、硬件及网络部署</p>
                            <p>IBM、Lenovo 、DELL、HP Cisco apple、Microsoft、HUAWEI、浪商、H3C等产品的项目安装、实施、部署</p>
                            <p style={{ textIndent: 0 }}>6、企业IT业务外包</p>
                            <p>针对不同企业的需求，提供专业资深的工程师做外包服务</p>
                            <p style={{ textIndent: 0 }}>7、IT技能培训</p>
                            <p>针对IBM、Lenovo 、DELL、HP Cisco apple、Microsoft、HUAWEI、浪商等、H3C产品的技术培训</p>
                        </div>
                    </div>
                </div> : null
            }

            <FooterBar />

        </div>
    );
}

export default Service;
